import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { moveTopOnPageSmooth, moveTopOnPageAuto } from "../../../apis/CommonFunc";
const StyleLogoTemplate = styled.div`
    height: 70px;
    display: flex;
    align-items: center;
    user-select: none;
    a{
        height: 100%;
        display: flex;
        align-items: center;
    }
`;

const StyledLogo = styled.img`
    width: 80px;
    margin: 0px 20px;
`;


const Logo = () => {
    const onClickMoveHome = (e) => {
        if(window.location.pathname === "/") {
            e.preventDefault();
            moveTopOnPageSmooth();
        } else {
            moveTopOnPageAuto();
        }
    }

    return (
        <StyleLogoTemplate>
            <Link to="/" onClick={onClickMoveHome}>
                <StyledLogo src={`${process.env.PUBLIC_URL}/images/Logo.png`} alt="Metarex" title="Metarex" media-simple="true"/>
            </Link>
        </StyleLogoTemplate>
    )
}

export default Logo;
