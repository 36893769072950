import axios from "axios";

export const removeLocalStorage = () => {
    localStorage.removeItem('findIdMobile');
    localStorage.removeItem('authTokenMobile');
}

export const isSessionValidate = () => {
    const target = 'sessionId';
    return localStorage.getItem(target) !== null && typeof localStorage.getItem(target) !== 'undefined' && localStorage.getItem(target) !== 'undefined';
}

export const isValidationSessionId = () => {
    const url = `${process.env.REACT_APP_API_METAREX}/api/user/isValidSessionId`;
    let result = true;
    axios
        .get(url, {withCredentials : true})
        .then(response => {
            if(!JSON.parse(response.data.result)) {
                alert('세션이 만료되었습니다. 다시 로그인해주세요.');
                localStorage.removeItem('sessionId');
                window.location.href = '/signin';
                result = false;
            }
        })
        .catch(e => console.log(e));
    
    return result;
}

// 서버측의 sessionId를 삭제(만료)시키는 API
export const removeCookieAPI = async () => {
    const url = `${process.env.REACT_APP_API_METAREX}/api/user/removeSessionId`;

    const instance = axios.create({
        withCredentials: true
    })
    
    await instance
        .post(url)
        .then(response => {
            if(JSON.parse(response.data.result)) {
                // console.log(response.data);                
            }
        }).catch(e => {
            console.log(`error message: ${e}`);
        });
}

export const userLocaleToServer = async (locale) => {
    const url = `${process.env.REACT_APP_API_METAREX}/api/user/localeBySessionId?locale=${locale}`;
    const instance = axios.create({
        withCredentials: true
    })
    
    return await instance
        .post(url)
        .then(response => {
            console.log(response.data)
        }).catch(e => {
            console.log(`error message: ${e}`);
        });
}