import axios from "axios";
import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { isSessionValidate } from "../../common/BrowserStorage";
import RichTextEditor from "../../common/RichTextEditor";
import { isNull, isValidEmail } from "../../common/Validation";
import ContactInputBox from "./ContactInputBox";

const StyledContactForm = styled.div`
    width: 100%;
    max-width: 777px;
    margin-top: 50px;
    margin-bottom: 230px;
`;

const StyledButtonDiv = styled.div`
    padding-top: 70px;
    width: 100%;
    display: flex;
    justify-content: center;

    button, a {
        width: 155px;
        height: 48px;
        cursor: pointer;
        border: none;
        transition: all 0.3s;
        border-radius: 2px;

        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 14px;
        letter-spacing: -0.02em;
        text-align: center;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .cancel {
        background-color: #fff;
        color: #05141F;
        border: 1px solid #D9DDE2;
        margin-right: 10px;
    }
    .post {
        background-color: #2f80ed;
        color: #fff;
        border: 1px solid #2f80ed;
    }

    @media only screen and (max-width: 768px) {
        padding-top: 100px;
    }
`;

const ContactForm = () => {
    const [text, setText] = useState("");
    const [email, setEmail] = useState("");
    const [title, setTitle] = useState("");
    const {t} = useTranslation();

    useEffect(()=>{
        const url = new URL(window.location.href);
        const urlParam = url.searchParams;
        if(isSessionValidate()){
            userEmailBySessionId();
        }
        
        if(text === "") {
            const initText = isNull(urlParam.get('content')) ? "" : decodeURIComponent(urlParam.get('content'));
            setText(initText);
        }
        if(title === '') {
            const initTitle = isNull(urlParam.get('title')) ? "" : decodeURIComponent(urlParam.get('title'));
            setTitle(initTitle);            
        }
    },[]);

    const userEmailBySessionId = async () => {
        const url = `${process.env.REACT_APP_API_METAREX}/api/user/userEmailBySessionId`;
        await axios
            .get(url, {withCredentials : true})
            .then(response => {
                if(JSON.parse(response.data.result)) {
                    setEmail(response.data.email);
                }
            })
            .catch(e => console.log(e));
    }


    const 문의사항_등록하기 = () => {
        if(email === "" || isNull(email)) {
            alert(t('contact_us_alert_email'));
            return false;
        }

        if(!isValidEmail(email)) {
            alert(t('contact_us_alert_email_incorrect'));
            // setOnFocus(true); 포커스는 나중에 하자
            return false;
        }

        if(title === "" || isNull(title)) {
            alert(t('contact_us_alert_title'));
            return false;
        }

        if(text === "" || isNull(text)) {
            alert(t('contact_us_alert_contents'));
            return false;
        }

        const userNo = null;
        const sessionId = localStorage.getItem('sessionId');
        const realestateNo = 0;
        const boardContactUsReplyNo = 0;
        const subjectKr = title;
        const markupStr = text;
        
        contactUsInsert({
            userNo, 
            email,
            sessionId,
            realestateNo, 
            subjectKr,
            markupStr,
            boardContactUsReplyNo,
        });
    }

    const history = useHistory();
    const contactUsInsert = async (json) => {
        const url = `${process.env.REACT_APP_API_METAREX}/contactUs/insert`;

        const instance = axios.create({
            withCredentials: true
        });

        await instance
            .post(url, json)
            .then(response => {
                alert(response.data.message);
                if(JSON.parse(response.data.result)) {
                    if(-1 < document.referrer.indexOf("/contactUs")) {
                        window.location.href = '/';
                    } else if(document.referrer === "") {
                        window.location.href = '/';
                    }
                    history.goBack();
                } else {
                    alert(response.data.message);
                }
            }).catch(e => {
                console.log(`error message: ${e}`);                
            });
    }

    return (
        <StyledContactForm>
            <ContactInputBox type="email" placeholder={t("contact_us_email_placeholder")} item={email} setItem={setEmail}/>
            <ContactInputBox type="text" placeholder={t("contact_us_title_placeholder")} item={title} setItem={setTitle}/>
            <RichTextEditor item={text} setText={setText}/>
            <StyledButtonDiv>
                <Link to="/" className="cancel">{t("contact_us_btn_cancel")}</Link>
                <button type="button" className="post" onClick={문의사항_등록하기}>{t("contact_us_btn_submit")}</button>
            </StyledButtonDiv>
        </StyledContactForm>
    )
}

export default ContactForm;