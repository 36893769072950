import React, { useRef, useLayoutEffect } from "react";


import TopArea from "./TopArea";
import Services from "./Services";
import Works from "./Works";
import Partnerships from "./Partnerships";
import News from "./News";
import GetItOn from "./GetItOn";


import styled from "styled-components";


const MainDiv = styled.div`
  
    @media screen and (min-width: 1200px) {
    }  
    @media screen and (max-width: 1199px) { 
    }  
    @media (max-width: 768px) {  
    }
    @media screen and (max-width: 414px) { 
    }
`;



function vhToPixels (vh) {
    return Math.round(window.innerHeight / (100 / vh)) + 'px';
}

function resizeHandler( event ) {

    const newWidth = Number(window.innerWidth);
    const newHeight = Number(window.innerHeight);

    // video ratio is 1:0.5625
    const myVideo 					        = document.getElementById( 'my-video' );
    const topAreaOnVideoBackground 	= document.getElementById( 'top-area-on-video-background' );


    //myVideo.setAttribute('width', newHeight/0.5625);
    console.log('newHeight=%o', newHeight);
    myVideo.setAttribute('width', newWidth);
    const minimumHeight = 870;	
    if( newHeight < minimumHeight ) {
      myVideo.setAttribute('height', minimumHeight);
      topAreaOnVideoBackground.style.height= `${minimumHeight}px`;
    } else {
      myVideo.setAttribute('height', vhToPixels(100));
      topAreaOnVideoBackground.style.height=vhToPixels(100);
    }	

}

const MainBanner = () => {

    const refAbout   = useRef(null);
    const refWorks   = useRef(null);
    const refPress   = useRef(null);
    const refContact = useRef(null);


    useLayoutEffect(()=>{
      resizeHandler();
      window.addEventListener('resize', resizeHandler);
      scrollToElementByPathname();
    }, []);    

    useLayoutEffect(()=>{

      scrollToElementByPathname();

    }, [window.location.pathname]);  

    const scrollToElementByPathname = () => {
      const uri = String(window.location.pathname).replace('/','');

      if( uri === "about" ) {        
        scrollToElement(refAbout);
      } else if( uri === "works" ) {
        scrollToElement(refWorks);
      } else if( uri === "press" ) {
        scrollToElement(refPress);
      }
    };

    const scrollToElement = (ref) => {
        ref.current.scrollIntoView({ inline: "center", behavior: 'smooth', block: 'start'});
    };



    return (
        <MainDiv>
            {/**Top video area */}
            <section>
              <TopArea />
            </section>

            {/** Metalive app download for Android */}
            <section>  
              <GetItOn />
            </section>

            {/**PARTNERSHIP */}
            <section> 
              <Partnerships/>
            </section>

            {/**Services */}
            <section ref={refAbout}>
              <Services />
            </section>

            {/** Works  */}
            <section ref={refWorks} >
              <Works />                
            </section>

            <div ref={refPress} >
              <News />
            </div>
        </MainDiv>        
    )
                      }

export default MainBanner;