import React, { useRef, useLayoutEffect } from "react";


import TopArea from "./TopArea";
import Services from "./Services";

import Partnerships from "./Partnerships";
import News from "./News";
import GetItOn from "./GetItOn";


import { t } from "i18next";

import styled from "styled-components";


const MainDiv = styled.div`


    @keyframes gear_spin {
        0%  {-webkit-transform: rotate(58deg);}
        100% {-webkit-transform: rotate(418deg);}
    }

    @keyframes gear_spin_reverse {
        0%  {-webkit-transform: rotate(360deg);}
        100% {-webkit-transform: rotate(0deg);}
    }


    @media screen and (max-width:786px) {
    } 
  
  
      .section.type-2.thkim_about_area {
        background-image: url(images/about_background.png);
        background-size: 60%;
        background-repeat: no-repeat;
        background-position: right top;
  
      }
  
      @import url('https://fonts.googleapis.com/css?family=Gothic+A1:100&subset=korean');
        
  
      .thkim_spin {
        -webkit-animation: spin 6s infinite linear;
      }
    
      .thkim_works {
        background-image: url(images/works_pc_background.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        height: 420px;
        text-align: center;
        position: relative;
      }
  
      .thkim_gear {
        display: inline-block;
        position: absolute;
        -webkit-animation: gear_spin 6s infinite linear;
        width: 50px;
        line-height: 420px;
        vertical-align: middle;
      }
  
      .thkim_gear_01 {
        width: 120px;
        height: 120px;
        left: 42%;
        @media screen and (max-width:786px) {
            left: 44%;
        }
        top: 28%;
        -webkit-animation: gear_spin_reverse 3s infinite linear;
      }
  
      .thkim_gear_02 {
        width: 90px;
        height: 90px;
        right: 40.2%;
        top: 40%;
        @media screen and (max-width:786px) {
            right: 50.8%;
            top: 43%;
        }
        -webkit-animation: gear_spin 3s infinite linear;
      }
  
      .thkim_gear_03 {
        width: 60px;
        height: 60px;        
        left: 49%;
        top: 57%;
        @media screen and (max-width:786px) {
            left: 43.7%;
            top: 57%;
        }
        -webkit-animation: gear_spin_reverse 3s infinite linear;
      }
  
      .thkim_gear_spin_arrow {
        position: absolute;
        left: 37.5%;
        top: 14%;
        @media screen and (max-width:786px) {
            left: 13%;
            top: 17%;
        }
        -webkit-animation: spin 16s infinite linear;
        width: 300px;
        @media screen and (max-width:786px) {
            width: 260px;
        }
      }
  
    
  
      .thkim_works_bottom_button_item {
        display: inline-block;
        position: relative;
        color: #ffffff;
        font-weight: bold;
        font-size: 28px;
        padding: 8px;
        cursor: pointer;
      }
    
  
      .thkim_works_bottom_button {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        -webkit-appearance: none;
        -webkit-border-radius: 0;
        border: 0px solid #ffffff;
        
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
        -webkit-filter: grayscale(1) brightness(40%);
        filter: grayscale(1) brightness(40%);      
        -webkit-transition:all 0.7s, -webkit-transform 0.7s;
        transition:all 0.7s, transform 0.7s;
      }
  
      .thkim_works_bottom_button_label {
        display: inline-block;
        position: absolute;
        left: 0px;
        top: 40px;
        @media screen and (max-width:786px) {
            top: 28px;
        }
      }
  
      .thkim_works_bottom_button_item, .thkim_works_bottom_button_label {
        width: 320px;
        height: 124px;
        @media screen and (max-width:786px) {
            width: 100%;
            max-width: 300px;
            height: 100px;
        }
      }
  
  
      .thkim_works_bottom_button_item:hover .thkim_works_bottom_button {
        -webkit-filter: grayscale(0) brightness(100%);
        filter: grayscale(0) brightness(100%);      
      }
      .thkim_works_bottom_button_item:hover .thkim_works_bottom_button_label {
        text-shadow: 1px 1px 1px #333;
      }
  
      .works_bottom_button_01_aster_analysis {
        background-image: url(images/works_bottom_button_01_aster_analysis.png);
        background-size: cover;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        -webkit-appearance: none;
        -webkit-border-radius: 0;
        border: 0px solid #ffffff;
        color: #ffffff;
        font-weight: bold;
        font-size: 28px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
        -webkit-filter: grayscale(1) brightness(40%);
        filter: grayscale(1) brightness(40%);      
        -webkit-transition:all 0.7s, -webkit-transform 0.7s;
        transition:all 0.7s, transform 0.7s;
      }
  
      .works_bottom_button_02_HR {
        background-image: url(images/works_bottom_button_02_HR.jpg);
      }
  
      .works_bottom_button_03_Travel {
        background-image: url(images/works_bottom_button_03_Travel.jpg);
      }
  
      .works_bottom_button_04_Finance {
        background-image: url(images/works_bottom_button_04_Finance.jpg);
      }
  
      .works_bottom_button_05_Shopping {
        background-image: url(images/works_bottom_button_05_Shopping.jpg);
      }
  
      .works_bottom_button_06_Entertainment {
        background-image: url(images/works_bottom_button_06_Entertainment.jpg);
      }
  
      .works_bottom_button_07_Beauty {
        background-image: url(images/works_bottom_button_07_Beauty.jpg);
      }
      
  
      .thkim_works_bottom_button_wrapper {
        text-align: center;
      }
  
  
  
      .partner-body {
        margin-bottom: 30px;
      }
  
      .history-title {
        margin-top: 60px;
        padding-bottom: 20px;
      }
  
      .history-date {
        display: inline-block;
        width: 70px;
        @media (max-width: 767px) {
          color: #0095ff;
          display: block;
          padding-bottom: 5px;
        }
      }
  
  
      @media screen and (min-width: 1200px) {
        .centered {
          position: absolute;
          z-index: -1;
          top: 300px;
          left:0px;
        }
      }
  
      @media screen and (max-width: 1199px) {
  
        .thkim_display_pc_only {
          display: none;
        }  
  
      }
  
      @media (max-width: 767px) {  
      }
        
      @media (min-width: 415px) and (max-width: 991px) {
        .section {
          padding: 70px 0;
        }  
      }
  
      @media screen and (max-width: 414px) { 
  
      }
`;

const Works = () => {

    return (
        <MainDiv>
          {/** Works  */}
          <div className="section type-3 stats">
              <div className="section-headlines">
                  <h4>Works</h4>
                  <h2>A new social media model
                      where contributors get
                      big perks</h2>
                  <div>Users become platform stakeholders, maintaining control over their data, and earning cryptocurrency rewards for each contribution they make.</div>
              </div>
              <div id="thkim_works_container" className="container">
                  <div className="row ">
                  <div className="thkim_works">
                      <img  className="thkim_gear thkim_gear_01" src="images/gear.png?v=20220803_1348" alt="" />
                      <img  className="thkim_gear thkim_gear_02" src="images/gear.png?v=20220803_1348" alt="" />
                      <img  className="thkim_gear thkim_gear_03" src="images/gear.png?v=20220803_1348" alt="" />
                      <img className="thkim_gear_spin_arrow" src="images/pic3-382x382_about_spin.png?v=0000" alt="" />
                  </div>
                  </div>
              </div>
              <div>
                  <div className="thkim_works_bottom_button_wrapper">
                      <div className="thkim_works_bottom_button_item" onClick={() => {window.open('https://aster879admin.uml.kr/', '_blank');}}>
                          <button className="thkim_works_bottom_button works_bottom_button_01_aster_analysis" type="button"></button><div className="thkim_works_bottom_button_label">ASTER analysis</div>
                      </div>
                      <div className="thkim_works_bottom_button_item" onClick={() => {window.open('http://zeni.uml.kr/', '_blank');}}>
                          <button className="thkim_works_bottom_button works_bottom_button_02_HR" type="button"></button><div className="thkim_works_bottom_button_label">HR</div>
                      </div>
                      <div className="thkim_works_bottom_button_item" onClick={() => {window.open('https://tbot.uml.kr/', '_blank');}}>
                          <button className="thkim_works_bottom_button works_bottom_button_03_Travel" type="button"></button><div className="thkim_works_bottom_button_label">Travel</div>
                      </div>
                  </div>
                  <div className="thkim_works_bottom_button_wrapper">
                      <div className="thkim_works_bottom_button_item" onClick={() => {window.open('http://uml.kr/bbot/', '_blank');}}>
                          <button className="thkim_works_bottom_button works_bottom_button_05_Shopping" type="button"></button><div className="thkim_works_bottom_button_label">Shopping</div>
                      </div>
                      <div className="thkim_works_bottom_button_item" onClick={() => {window.open('http://uml.kr/ginibot/', '_blank');}}>
                          <button className="thkim_works_bottom_button works_bottom_button_06_Entertainment" type="button"></button><div className="thkim_works_bottom_button_label">Entertainment</div>
                      </div>
                      <div className="thkim_works_bottom_button_item" onClick={() => {window.open('https://kbot.uml.kr/', '_blank');}}>
                          <button className="thkim_works_bottom_button works_bottom_button_07_Beauty" type="button"></button><div className="thkim_works_bottom_button_label">Beauty</div>
                      </div>
                  </div>
              </div>
          </div>
        </MainDiv>        
    )
                      }

export default Works;