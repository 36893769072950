import React from "react";
import { t } from "i18next";
import styled from "styled-components";

import HowToMoveHanbitco2Metamask from "../../components/Hanbitco/HowToMoveHanbitco2Metamask";


const MainDiv = styled.div`
  .mexc-logo-div {
    background: rgba(0, 0, 0, 0);
    text-align: center;
    padding: 16px 0px;
    a {
      text-decoration: none;
    }
    .signup-mexc {
      color: rgb(255, 255, 255);
      font-size: 42px;
      vertical-align: bottom;
      font-weight: 600;
      @media screen and (max-width:786px) {
        font-size: 22px;
        font-weight: 400;
      }
    }

    .mexc-logo {
      width: 300px;        
      @media screen and (max-width:786px) {
        width: 160px;
      }
    }

    .signup-mexc-in-korean {
      color: rgb(255, 255, 255);        
      vertical-align: -webkit-baseline-middle;
      font-size: 34px;
      @media screen and (max-width:786px) {
          font-size: 20px;
      }
      font-weight: 600;
    }
  }

  .go-to-logo {
    height: 50px;
    padding: 0px 30px;
    @media (max-width: 767px) {
      height: auto;
      width: 200px;
      padding: 5px 0px;    
      &.logo-metalive {
        padding-top: 20px
      }
    }
  }

  #my-video {
    position: fixed;
    z-index: -1;
    top: 0px;
    object-fit:cover;
  }


  .thkim_text_shadow_01 {
    text-shadow: 1px 1px 2px #666;
  }


  @import url('https://fonts.googleapis.com/css?family=Gothic+A1:100&subset=korean');

  .how-to-move-hanbitco-2-metamask {
    padding: 20px;
  }

  @media screen and (min-width: 1200px) {
    .centered {
      position: absolute;
      z-index: -1;
      top: 300px;
      left:0px;
    }
  }

  @media screen and (max-width: 1199px) {    
  }

  @media (max-width: 991px) {  
    .section {
      padding: 70px 0;
    }
  }

  @media (max-width: 767px) {  
  }
    
  
  @media screen and (max-width: 414px) {
  }

`;

const TopArea = () => {
    return (
        <MainDiv>
            {/**The video */}
            <video autoPlay muted loop id="my-video" width="1920" height="900" poster="/images/video-poster/astercoin-org-video_poster.jpg">
                {/**<source src="video/202203-07-01_conv.webm?v=20220803_1348" type="video/webm" /> */}
                <source src="video/20221121_1502_metarex_main_video.mp4?v=20220803_1348" type="video/mp4" />
            </video>            
            <div id="top-area-on-video-background" className="section type-1 big splash">  
                <div className="container">
                    <div className="splash-block">
                      <div className="centered">
                          <div className="container">
                          <div className="section-headlines">     
                              <h1 id="top-title-on-video" className="thkim_text_shadow_01" style={{color: '#0095ff'}}>{t('aster_is_the_second_dollar_used_on_the_second_earth')}</h1>
                              <p id="thkim_text_shadow_01" className="thkim_text_shadow_01" >{t('aster_is_aiming_at_metaverse_coin_constructing_a_whole_new_ecosystem_as_a_base_currency_in_metaverse')}</p>
                          </div>
                              <a className="go-to-logo-wrapper" href="https://metarex.global/" target="_blank">
                              <img className="go-to-logo logo-metarex" src="images/logos/002_logo_metarex.png?v=20220803_1348" alt="" />
                              </a>
                              <a className="go-to-logo-wrapper" href="https://www.metalive.kr/" target="_blank">
                              <img className="go-to-logo logo-metalive" src="images/logos/003_logo_metalive.png?v=20220803_1348" alt="" />
                              </a>
                              <a className="go-to-logo-wrapper" href="http://ai-loan.co.kr/" target="_blank">
                              <img className="go-to-logo logo-ailoan" src="images/logos/004_logo_ailoan.png?v=20220803_1348" alt="" />
                              </a>
                          </div>
                          <div className="mexc-logo-div">
                              <a href="https://m.mexc.com/auth/signup?inviteCode=1Q9KH" rel="noopener noreferrer" target="_blank" title="MEXC Global">
                              <span className="signup-mexc">{t('signup')}</span>
                              <img src="/images/logos/svg/MEXC_Global_white.svg" alt="MEXC Global" className="mexc-logo" />
                              <span className="signup-mexc-in-korean">{t('signup_mexc_in_korean')}</span>
                              </a>
                          </div>
                          { false &&
                            <div className="how-to-move-hanbitco-2-metamask">
                              <HowToMoveHanbitco2Metamask
                                fontColor="white"
                              />
                            </div>                          
                          }
                      </div>
                    </div>
                </div>
            </div>
        </MainDiv>        
    )
}

export default TopArea;