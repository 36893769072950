import i18n from "i18next";
import {initReactI18next } from "react-i18next";

import korean from './languages/ko.json';
import english from './languages/en.json';
import chinese from './languages/cn.json';
import { isNull } from "../../../common/Validation";
import { Cookies } from "react-cookie";


const resource = {
    ko: {
        translation: korean
    },
    en: {
        translation: english
    },
    cn: {
        translation: chinese
    }
}

const language = localStorage.getItem("locale");

if(isNull(language)) { 
    localStorage.setItem("locale", "ko"); 
}

console.log(`current language=${localStorage.getItem("locale")}`)

const cookies = new Cookies();
cookies.set("locale", localStorage.getItem("locale"), {
    path: "/",
    secure: true,
    sameSite: "none"
});

i18n
    .use(initReactI18next)
    .init({
        resources: resource,
        lng: [language],
        fallbackLng: "ko", // 초기화값
        debug: false,
        keySeparator: false,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;