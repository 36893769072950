import React, { useState, useEffect } from "react";
import { t } from "i18next";
import styled from "styled-components";


const StyledMainDiv = styled.div`
    text-align: center;
    .document-sending-hanbitco-to-metamask {
        .pdf-symbol-wrapper {
            .pdf-symbol {
                width: 60px;
                margin: 0px auto;
            }
        }
        .transfer-assets-from-hanbitco-to-metamask-wallet {
            margin-top: 10px;
            font-size: 20px;
            font-weight: 600;            
            color: #ffffff;            
            text-shadow: 1px 1px 2px #666;      
            &.color-black {
                color: #000000;
            }
        }
    }

    @media only screen and (max-width: 768px) {
    }

`;

const HowToMoveHanbitco2Metamask = ({fontColor}) => {
    return (
        <StyledMainDiv>
            <div className="document-sending-hanbitco-to-metamask">
                <div className="pdf-symbol-wrapper">
                    <a id="document-ko-kr" download href="docs/transfer_aster_assets_from_Hanbitco_to_Metamask_wallet.pdf?v=20220803_1348">
                        <img className="pdf-symbol" src="images/pdf_symbol_blue.png?v=20220803_1348" alt="" />
                    </a>
                </div>
                <div>
                    <br />
                    <span className={"transfer-assets-from-hanbitco-to-metamask-wallet color-" + (fontColor == 'black' ? 'black' : fontColor)}>{t('transfer_assets_from_hanbitco_to_metamask_wallet')}</span>
                </div>
            </div>
        </StyledMainDiv>
    )
}

export default HowToMoveHanbitco2Metamask;