import React from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const RichTextEditor = ({item, setText}) => {
    const style = { height: "400px"};

    const onChangeEvent = (e) => {
        setText(e);
    }

    // Quill Tool bar
    const modules = {
        toolbar: {
          container: [
            [{ size: ["small", false, "large", "huge"] }],
            [{ color: [] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { align: [] }
            ],
            ["image", "video"],
            ["clean"]
          ],
        //   handlers: { image: this.imageHandler }
        },
        clipboard: { matchVisual: false }
    };

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "size",
        "color",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "video",
        "align"
    ];


    return (
        <ReactQuill 
            style={style}
            theme="snow"
            onChange={onChangeEvent}
            modules={modules}
            formats={formats}
            value={item}
        >
        </ReactQuill>
    )
}

export default RichTextEditor;