import React, { useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom'; 
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {t} from "i18next";

import { isNull, mobile } from "../../apis/Validation";
import queryString from 'query-string';

import Logo from "../feature/Header/Logo";
import Menu from "../feature/Header/Menu";
import DrawerMenu from "../feature/Header/DrawerMenu";


import styled from "styled-components";


const StyledHeaderTemplate = styled.header`
    background: rgba(255,255,255,0.860529);
    width: 100vw;
    height: 70px;
    position: fixed;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width:786px) {
        z-index: 1000;
    }
`;

const StyledMenuContainerDiv = styled.div`
    display: flex;
    width: 100%;
    @media screen and (max-width:786px) {
        display: none;
        position: absolute;
        top: 70px;
        background-color: rgba(255,255,255,0.630417);;
        width: 100%;
        &.clicked {
            display: block;
        }
    }
`

const StyledHeaderContents = styled.div`
    max-width: 1440px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

// 헤더에서 왼쪽부분
const StyledWrapMenu = styled.div`
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    .dropdown-content {
        display: none;
        &.clicked {
            display: block;
        }
    }

    .header-1depth {
        padding: 0px 20px;
        .header-1depth-link {
            height: 70px;
            width: 145px;
            line-height:71px;
            font-size: 17px;
        }
    }
    
    
    @media screen and (max-width:786px) {
        display: block;
        background-color: rgba(255,255,255,0.860529);;
        width: 100%;        
    }
`;


// 헤더의 오른쪽 부분
const StyledLoginWrap = styled.div`
    display: flex;
    a {
        float: none;
        color: #333;
        display: block;
    }
    span {
        color: #333;
        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center; 
    }

    .dropdown-content {
        display: none;
        &.clicked {
            display: block;
        }
    }

    @media screen and (max-width:786px) {
        display: block;
        background-color: rgba(255,255,255,0.630417);;
        width: 100%;
    }
`;

const StyledLoginItemDiv = styled.div`
    height: 100%;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    a {
        width: 150px;
        height: 70px;
        color: #333;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center; 
        display: flex;
        justify-content: center;
        align-items: center;
    }
    @media screen and (max-width:786px) {
        display: flex;
        align-items: flex-start;
        padding: 0px 20px;
        background-color: #3D85F0;
        width: 100%;        
        flex-direction:column;
        >a{
            justify-content: flex-start;
        }
        span {
            height: 70px;
            display: flex;
            justify-content: center;    
            align-items: center;
        }
    }
`;

const MobileButtonTemplate = styled.div`
    display: none;
    width: 70px;
    height: 70px;
    @media screen and (max-width:786px) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;




const Header = () => {

    const LOCALE = localStorage.getItem('locale') === 'ko' ? "한국어" : localStorage.getItem('locale') === 'en' ? "English" : "简体中文";
    const [active, setActive] = useState({
                                            introduce:false,
                                            header_purchase:false,
                                            sales:false,
                                            support:false,
                                            translate: false,
                                            user_info: false
                                        });
    const [activeMobile, setActiveMobile] = useState(false);
    const [isSession, setIsSession] = useState(false);
    // const userSelector = useSelector((state) => state.user); // redux user값

    const DropDown = (e) => {
        const target = e.currentTarget.dataset.dropdownType.trim();
        ResetActive(target);
    }

    const DropDownMobile = (e) => {
        e.preventDefault();
        setActiveMobile(!activeMobile);
        ResetActive();
    }

    const ResetActive = (target) => {
        const newActive = {};
        for(let key in active) {
            newActive[key] = false;
        }
        setActive({...newActive, [target] : !active[target]});
    }


    const menuRef = useRef();
    const handleClickOutSide = ({ target }) => {
        if(mobile()){
            if(!menuRef.current.contains(target)) {
                setActiveMobile(false);
            }
        } else {
            if(!menuRef.current.contains(target) || (target.tagName === 'IMG') || (target.tagName === 'A')) {
                ResetActive();
            }
        }
    }

    useEffect(() => {
        window.addEventListener("click", handleClickOutSide);
        return () => {
            window.removeEventListener("click", handleClickOutSide);
        }
    });


    const changeLanguage = (e) => {
        const pathname = window.location.pathname;
        const language = e.target.dataset['i18n'];
        localStorage.setItem('locale', language);
        window.location.replace(pathname);
    }

    return (
        <>       
            <StyledHeaderTemplate ref={menuRef}>
                <StyledHeaderContents > 
                    <Logo/>
                    <StyledMenuContainerDiv className={!mobile() ? "" : activeMobile ? "clicked" : ""}>
                        <StyledWrapMenu>
                            <div className="header-1depth" >
                                <Link className="header-1depth-link" to="/about">{t("header_menu_about")}</Link>
                            </div>
                            <div className="header-1depth" >
                                <Link className="header-1depth-link" to="/works">{t("header_menu_works")}</Link>
                            </div>
                            <div className="header-1depth" >
                                <Link className="header-1depth-link" to="/press">{t("header_menu_press")}</Link>
                            </div>
                            {false &&
                            <div className="header-1depth" >
                                <Link className="header-1depth-link" to="/contact">{t("header_menu_contact")}</Link>
                            </div>   
                            }           
                            { isSession && 
                                <Menu onClick={DropDown} category={'sales'} categoryName={t("header_menu_sales")} active={active}>
                                    <li onClick={DropDownMobile}><Link to="/MarketView">{t("header_view_market")}</Link></li>
                                    <li onClick={DropDownMobile}><Link to="/SalesRegistRation">{t("header_sales_registration")}</Link></li>
                                    {isSession && <li onClick={DropDownMobile}><Link to="/SaleHistory">{t("header_sales_history")}</Link></li>}
                                </Menu>
                            }
                        </StyledWrapMenu>
                        
                        <StyledLoginWrap>
                            <Menu className="lang" onClick={DropDown} category={'translate'} categoryName={LOCALE} active={active}>
                                <li><span data-i18n="ko" onClick={changeLanguage} style={LOCALE === '한국어' ? {color: '#3D85F0'} : {}}>한국어</span></li>
                                <li><span data-i18n="en" onClick={changeLanguage} style={LOCALE === 'English' ? {color: '#3D85F0'} : {}}>English</span></li>
                                <li><span data-i18n="cn" onClick={changeLanguage} style={LOCALE === '简体中文' ? {color: '#3D85F0'}: {}}>简体中文</span></li>
                            </Menu>

                            {false &&
                                <div>
                                    {isSession 
                                        ?
                                        <Menu className="sign_in" onClick={DropDown} category={'user_info'} categoryName={`${localStorage.getItem('email').split("@")[0]}`} active={active}>
                                            <li onClick={DropDownMobile}><Link to="/mypage">{t('header_mypage')}</Link></li>
                                            <li onClick={DropDownMobile}><Link to="/point">{t('header_point')}</Link></li>
                                        </Menu>
                                        :
                                        <StyledLoginItemDiv>
                                            <Link to="/signin" className="gnb-signin-out" onClick={()=>{setActiveMobile(!activeMobile)}}>{t("header_login")}</Link>
                                        </StyledLoginItemDiv>
                                    }
                                </div>
                            }
                        </StyledLoginWrap>
                    </StyledMenuContainerDiv>

                    {/* 모바일 버튼 */}
                    <MobileButtonTemplate onClick={DropDownMobile}>
                        <DrawerMenu clicked={activeMobile}/>
                    </MobileButtonTemplate>
                </StyledHeaderContents>
            </StyledHeaderTemplate>
        </>
    );
}


export default Header;