import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { openPage } from "../../common/CommonFunc";

import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faYoutube, faTwitterSquare, faFacebookSquare, faTelegram, faInstagram } from "@fortawesome/free-brands-svg-icons"


const StyledInformationTemplate = styled.div`
    width: 100vw;
    height: auto;
    background-color: #fff;
    display: flex;
    justify-content: center;
    
`;

const StyledContentsDiv = styled.div`
    width: 100%;
    height: 100%;
    max-width: 1440px;
    .social-media-wrapper {
        padding: 30px;
        @media screen and (max-width:786px) {
            padding: 0px 10px;
        }
        h2 {
            text-align: center;
        }
        .container{
            height: auto;
            width: auto;
            max-width: 320px;
            padding-top: 20px;
            border-bottom: 1px solid #D9DDE2;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .contact_us {
                display: flex;
                align-items: center;
                margin-left: 20px;
                img {
                    width: 252px;
                    height: 40px;
                }
                .wrap {
                    margin-left: 30px;
                    span {
                        font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 26px;
                        letter-spacing: 0em;
                    }
                }
            }
            .social-link {
                font-size: 40px;
                color: #595959;
                @media screen and (max-width:786px) {
                    padding: 0px 10px;
                }
            }
            .fa-youtube {
            }
        }
    }
    
    .inquiry {
        .wrap {
            padding-top: 40px;
            padding-right: 20px;
            height: 100%;
            display: flex;
            justify-content: space-between;
            span {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0em;
                margin: 20px auto;

            }
            a {
                font-size: 20px;
                font-style: normal;
                font-weight: 800;
                line-height: 24px;
                letter-spacing: 0em;
                margin-left: 30px;
                color: #000;
                &:hover {
                    color: #3D85F0;
                }
             }
        }
    }

    @media screen and (max-width:786px) {
        max-width: 100%;        
        padding: 40px 20px;
        .container{
            text-align: center;
            height: auto;
            flex-direction: row;            
            align-items: flex-start;
            justify-content: flex-start;
            .contact_us {
                flex-direction: column;
                align-items: flex-start;
                margin: 0px;
                img {
                    width: 160px;
                    height: 26px;
                    margin-bottom: 20px;
                }
                .wrap {
                    margin-left: 0px;
                    margin-bottom: 40px;
                    span {
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
            }
            .fa-youtube-square {
                margin-bottom: 20px;
            }
        }
        .inquiry {
            .wrap {
                padding: 20px 0px 40px 0px;
                flex-direction: column;
                justify-content: flex-start;
                span {
                    margin-left: 0px;
                    font-size: 14px;
                    margin-bottom: 40px;
                }
                a {
                    margin-left: 0px;
                    font-size: 20px;
                    line-height: 24px;
                    letter-spacing: 0em;
                    margin: 0px 30px 0px 0px;
                }
            }
        }

    }

`;

const InformationMetarex = () => {
    const {t} = useTranslation();
    const YOUTUBE_CHANNEL_URL = "https://www.youtube.com/channel/UCa9QflDnH839siB1rMH5Z5w";
    const TWITTER_CHANNEL_URL = "https://twitter.com/ASTERmanager";
    const TELEGRAM_CHANNEL_URL = "https://t.me/CoOJ1XtYjZM2N2Fl";
    const FACEBOOK_CHANNEL_URL = "https://www.facebook.com/%EC%95%84%EC%8A%A4%ED%84%B0%EC%BD%94%EC%9D%B8-Aster-Coin-103268205225597";
    const INSTAGRAM_CHANNEL_URL = "https://www.instagram.com/astercoinkr/";

    return (
        <StyledInformationTemplate>
            <StyledContentsDiv>
                <div className="social-media-wrapper">
                    <h2>SOCIAL MEDIA</h2>
                    <div className="container">                    
                        <Link className="social-link" to={{ pathname: YOUTUBE_CHANNEL_URL }}    target="_blank"     onClick={openPage}><FontAwesomeIcon icon={faYoutube}/></Link>
                        <Link className="social-link" to={{ pathname: TWITTER_CHANNEL_URL }}    target="_blank"     onClick={openPage}><FontAwesomeIcon icon={faTwitterSquare}/></Link>
                        <Link className="social-link" to={{ pathname: FACEBOOK_CHANNEL_URL }}   target="_blank"     onClick={openPage}><FontAwesomeIcon icon={faFacebookSquare}/></Link>
                        <Link className="social-link" to={{ pathname: INSTAGRAM_CHANNEL_URL }}  target="_blank"     onClick={openPage}><FontAwesomeIcon icon={faInstagram}/></Link>
                        <Link className="social-link" to={{ pathname: TELEGRAM_CHANNEL_URL }}   target="_blank"     onClick={openPage}><FontAwesomeIcon icon={faTelegram}/></Link>
                    </div>
                </div>                
                <div className="inquiry">
                    <div className="wrap">
                        <span className="address">111 SOMERST ROAD #06-07T 111 SOMERSET SINGAPORE (238164) | 201933593Z</span>
                        {false && 
                        <div className="inquiry_wrap">
                            <Link to="/contactus">{t("footer_contact_us")}</Link>
                            {false && <Link to="/termsofservice">{t("footer_terms_of_use")}</Link>}
                        </div>
                        }
                    </div>
                </div>
            </StyledContentsDiv>
        </StyledInformationTemplate>
    )


}

export default InformationMetarex;