import React, { useEffect } from "react";

import MainBanner from "../../Pages/Main/MainBanner"
import { removeLocalStorage } from "../../common/BrowserStorage";
import { moveTopOnPage } from "../../common/CommonFunc";

const Main = () => {
    useEffect(()=>{
        moveTopOnPage();
        removeLocalStorage();
    }, []);

    return (
        <>
        <MainBanner/>
        </>
    )
}

export default Main;