import React from "react";

const Partner = ({content}, key) => {

    return (
        <div key={key} className="trading-content">
            <div className="trading-content-information">                
                <img src={content.src} alt="partner icon" />
            </div>
        </div>
    )
    
}

export default Partner;