import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";
import ReactLoading from 'react-loading'; 
import Partner from "./Partner";
import { mobile } from "../../apis/Validation";

const MainDiv = styled.div`
    width: 100%;
    overflow-x: clip;

    .section-lastest-trading {
        background: rgba(255, 255, 255, 0);
        width: 100%;
    }
    .section-lastest-trading .wrap {
        width: 100%;
        height: 96px;
        white-space: nowrap;
    }
    .section-lastest-trading .wrap .slider {
        height: 100%;
        transition: 0.5s;
    }
    .section-lastest-trading .wrap .container {
        height: 100%;
        width: 100%;
        transition: 0.5s;
        font-size: 0.8rem;
        color: rgba(0, 0, 0, 0.87);
    }
    .section-lastest-trading .wrap .container .trading-content {
        display: inline-block;
        width: 200px;
        height: 100%;
        white-space: normal;
        padding: 10px;
        margin: 0px 10px;
    }
    .section-lastest-trading .wrap .container .trading-content .trading-content-information {
        padding: 0;
        width: 100%;
        text-align: center;
    }
`;

const LoadingProgressBarDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, .1);
`;

const Partnerships = () => {
    const [recentTradingListContents, setRecentTradingListContents] = useState([]);

    useLayoutEffect(() => {
        const data = [
            {
                src : "images/partnership/partnership_001.png?v=20220803_1348",
                alt : "Tembusu-partners logo image"
            },
            {
                src : "images/partnership/partnership_002.png?v=20220803_1348",
                alt : "Nanhua-futures logo image"
            },
            {
                src : "images/partnership/partnership_003.png?v=20220803_1348",
                alt : "ErisX logo image"
            },
            {
                src : "images/partnership/partnership_004.png?v=20220803_1348",
                alt : "Nanhua-USA-LLC logo image"
            },
            {
                src : "images/partnership/partnership_005.png?v=20220803_1348",
                alt : "Hehmeyer-trading-investments logo image"
            },
            {
                src : "images/partnership/partnership_006.png?v=20220803_1348",
                alt : "San-juan-mercantle-exchange logo image"
            },
            {
                src : "images/partnership/partnership_007.png?v=20220803_1348",
                alt : "China-everbright-bank logo image"
            },
            {
                src : "images/partnership/partnership_008.png?v=20220803_1348",
                alt : "Rakuten logo image"
            },
            {
                src : "images/partnership/partnership_009.png?v=20220803_1348",
                alt : "Straits-financial-Sigapore logo image"
            },
            {
                src : "images/partnership/partnership_010.png?v=20220803_1348",
                alt : "Straits-financial logo image"
            },
        ];
        setRecentTradingListContents(data);
    },[]);

    // --- rolling event start
    const [curLocation, setCurLocation] = useState(0);  // 배너 맨 왼쪽의 현재 위치
    const rollingStyles = {
        transform: `translate(${curLocation}px)`
    }

    const containerRef = useRef();

    useEffect(() => {
        let move = 270;             // 한 번 움직일 때의 이동 거리
        const ROLLING_SPEED = 3500; // interval 속도
        if(recentTradingListContents.length !== 0) {
            const containerWindowSize = containerRef.current === 'undefined' ? 0 : window.innerWidth;
            const containerLen = containerRef.current.scrollWidth;
            const interval = setInterval(() => {
                if(containerLen - containerWindowSize <= Math.abs(curLocation-move)) {
                    move = ((containerLen - containerWindowSize) % move);
                    if(!mobile()) { move += 16; }   // scollbar 가로 사이즈
                }
                setCurLocation(prevLocation => prevLocation-move);

                if(containerLen - containerWindowSize <= Math.abs(curLocation)){ setCurLocation(0) } 
            }, ROLLING_SPEED);
            return () => clearInterval(interval);    
        }
    }, [curLocation, recentTradingListContents]);

    // 브라우저의 가로 크기보다 최근 거래내역이 적을 경우, 거래내역을 브라우저의 가로 크기만큼 반복하여 채운다.
    const 최근_거래_내역_보여주기 = () => {
        const SIZE_ONE_BANNER = 250;
        const BROWSER_SIZE = window.innerWidth;
        const BANNER_SIZE = SIZE_ONE_BANNER * recentTradingListContents.length;
        let count = recentTradingListContents.length + 1;

        if(BANNER_SIZE < BROWSER_SIZE) {
            const NECESSARY_SIZE = BROWSER_SIZE - BANNER_SIZE;
            count += (Math.ceil(NECESSARY_SIZE / SIZE_ONE_BANNER));
        }

        let index = 0;
        const array = [];
        for(let i = 0; i < count; i++){
            array.push(recentTradingListContents[index]);
            if(index === (recentTradingListContents.length-1)) {
                index = 0;
                continue;
            }
            index++;
        }

        return array;
    }
    // --- rolling event end
    
    return (
        <MainDiv>
            <section id="clients">  
                <div className="container">
                    <div className="row">
                        <div className="partnership list_slide" >
                            <div className="wrapper">
                                <h4 className="title">PARTNERSHIP</h4>
                                <section className="section-lastest-trading">
                                    <div className="wrap">
                                        <div className="slider">
                                            {recentTradingListContents.length !== 0 
                                                ?
                                                <div className="container" style={rollingStyles} ref={containerRef}>
                                                    {최근_거래_내역_보여주기().map((content, index) => <Partner content={content} key={index}/>)}
                                                </div>
                                                :
                                                <LoadingProgressBarDiv>
                                                    <ReactLoading type={'spin'} color={'skyblue'} height={'40px'} width={'40px'} />
                                                </LoadingProgressBarDiv> 
                                            }
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>  
                </div>
            </section>            
        </MainDiv>
        
    )
}

export default Partnerships;