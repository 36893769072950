import React from "react";
import styled from "styled-components";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";

const StyledAsterCoinTemplate = styled.div`
    width: 100vw;
    height: auto;
    padding: 20px 0px;
    background-color: #F3F4F5;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width:786px) {
        width: 100%;
        height: 100%;
    }
`;

const StyledAsterCoinInfoDiv = styled.div`
    width: 100%;
    height: 100%;
    max-width: 1440px;
    .container {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        .whitepaper-pdf-symbol-wrapper {
            .whitepaper-pdf-symbol {
                width: 70px;
            }
        }
        .img_wrap {
            a {
                border: 1px solid #464648;
                color: #464648;
                display: inline-block;
                width: 191px;
                height: 50px;
                padding: 12px 48px 14px 24px;
                border-radius: 50px;
                text-align: center;
                margin: 14px;
                .wrap{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    img {
                        width: 119px;
                        height: 22px;
                    }
                    .fa-chevron-right {
                        margin-left: 16px;
                        font-size: 20px;
                    }

                }
            }
            .hanbitco {
                border: 1px solid #E01E3C;
                color: #E01E3C;
            }

        }
        h2 {
            padding-top: 60px;
            text-transform: Uppercase;
            color: #000;
            font-size: 28px;
            font-style: normal;
            font-weight: 300;
            line-height: 38px;
            letter-spacing: 0em;
            text-align: left;
            .footer-helper-title {
                text-align: center;
            }
            ol.footer-helper {   
                padding: 30px;          
                @media screen and (max-width:786px) {
                    padding: 19px;
                }   
                li {
                    list-style: auto;
                    list-style-type: decimal;
                    padding: 6px 0px;
                    font-size: 18px;
                    font-weight: 500;
                    .footer-helper-caution {
                        display: block;
                        font-size: 18px;
                        color: #fe2356;
                    }
                }
            }
        }
    }

    @media screen and (max-width:786px) {
        max-width: 100%;
        .container {
            height: auto;
            flex-direction: column;
            justify-content: none;
            padding: 20px;
            .img_wrap {
                margin-left: 0px;
                display:flex;
                flex-direction: column;
            }
        }
    }
`;



const PayWithAsterCoin = () => {
    return (
        <StyledAsterCoinTemplate>
            <StyledAsterCoinInfoDiv>
                <div className="container">

                    <h2 dangerouslySetInnerHTML={
                        {__html: t('footer_white_paper', {interpolation: {escapeValue: false}})}
                    } />
                    <div className="whitepaper-pdf-symbol-wrapper">
                        <a id="whitepaper-en-us" download href="docs/20220314_ASTER_whitepaper_0_98_eng.pdf?v=20220803_1348">
                            <img className="whitepaper-pdf-symbol" src="images/pdf_symbol_blue.png?v=20220803_1348" alt="" />
                        </a>
                        <a id="whitepaper-ja-jp" download href="docs/aster_japan_0000.pdf?v=20220803_1348">
                            <img className="whitepaper-pdf-symbol" style={{marginLeft: '10px', marginRight: '10px'}} src="images/pdf_symbol_blue_02.png?v=20220803_1348" alt="" />
                        </a>
                        <a id="whitepaper-ko-kr" download href="docs/20220802_ASTER_whitepaper_1_01_KOR.pdf?v=20220803_1348">
                            <img className="whitepaper-pdf-symbol" src="images/pdf_symbol_blue.png?v=20220803_1348" alt="" />
                        </a>
                    </div>


                    <h2 dangerouslySetInnerHTML={
                        {__html: t('footer_listed_exchange', {interpolation: {escapeValue: false}})}
                    } />
                    <div className="img_wrap">
                        <a href="https://www.mexc.com/exchange/ATC_USDT"
                            className="MEXC Global"
                            rel="noopener noreferrer" 
                            target="_blank"
                            title="MEXC Global"
                            style={{border: "1px solid #32B489"}}
                        >
                            <div className="wrap">
                                <img src={`${process.env.PUBLIC_URL}/images/footer/mexc_logo.svg`} alt="MEXC Global"/>
                                <FontAwesomeIcon icon={faChevronRight} style={{color: "#32B489"}}/>
                            </div>
                        </a>
                        <a href="https://www.bithumb.pro/en-us/spot/trade?q=ATC-USDT"
                            className="bitglobal"
                            rel="noopener noreferrer" 
                            target="_blank"
                            title="bithumb"
                        >
                            <div className="wrap">
                                <img src={`${process.env.PUBLIC_URL}/images/footer/bitglobal_logo.svg`} alt="bitglobal"/>
                                <FontAwesomeIcon icon={faChevronRight}/>
                            </div>
                        </a>
                    </div>

                </div>
            </StyledAsterCoinInfoDiv>
        </StyledAsterCoinTemplate>
    )
}

export default PayWithAsterCoin;