import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router';
import { Link } from "react-router-dom";
import { t } from "i18next";

import './App.css';

import Header from './header/Header';
import Footer from './footer/Footer';

import Main from './main/Main';
import ContactUs from './contactUs/ContactUs';

import NoticePopup from "./Modal/MainPopup/NoticePopup";

import HowToMoveHanbitco2Metamask from "./Hanbitco/HowToMoveHanbitco2Metamask";


const App = () => {
  
  useEffect(()=>{
  }, []);

  //2022-08-22 거래소는 MEXC만 지원한다는 팝업
  const [isPopup20230508HanbitcoDelistingShow , setIsPopup20230508HanbitcoDelistingShow] = useState(false);

  return (
    <div className="App">
      {/* 헤더 고정 */}
      {true && <Header/>}

      {/* 이동할 페이지 컴포넌트 */}
      {/* 메칭되는 페이지가 없다면 메인으로 돌아가도록 셋팅 / 혹은 페이지가 존재하지 않습니다.*/}
      <Switch>
        {/* 메인 */}
        <Route exact path="/" component={Main}/>
        <Route exact path="/about" component={Main}/>
        <Route exact path="/works" component={Main}/>
        <Route exact path="/press" component={Main}/>
        <Route exact path="/contact" component={ContactUs}/>

        <Route path="/contactus" component={ContactUs} />
      </Switch>

      {/* footer 고정 */}
      {true && <Footer/>}

      { isPopup20230508HanbitcoDelistingShow &&
        <NoticePopup
              setIsPopupShow={setIsPopup20230508HanbitcoDelistingShow}
              popupTitle={"안내"}
              popupImageClickLink={"/notice?boardNo=45"}
              popupImageClickLinkTarget={"_self"}
              showsHidingCheckBoxForADay={false}
        >
          <div className='popup-20230508-hanbitco-delisting'>
            <ul>
                <li>
                    <h3>{t('we_will_inform_you_about_the_delisting_of_hanbitco_exchange')}</h3>
                    <HowToMoveHanbitco2Metamask
                      fontColor="black"
                    />
                    <br />
                    <br />
                    <p>
                    {t('first_of_all_i_would_like_to_apologize_to_those_who_watched_the_aster_project')}
                      <ol dangerouslySetInnerHTML={
                          {__html: t('the_distribution_volume_part_claimed_by_hanbitco_exchange_was_fully_explained_in', {interpolation: {escapeValue: false}})}
                      } />
                      <br />
                      <br />
                      {t('thank_you')}
                      <br />
                      <br />
                      <br />
                      <br />
                    </p>
                </li>
            </ul>
          </div>
        </NoticePopup>
      }
      
    </div>
  );
}

export default App;
