import React from "react";

import InformationMetarex from "./InformationMetarex";
import PayWithAsterCoin from "./PayWithAsterCoin";

const Footer = () => {
    return (
        <>
        <PayWithAsterCoin/>
        <InformationMetarex/>
        </>
    )
}

export default Footer;