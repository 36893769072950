import React from "react";
import styled from "styled-components";


const MainDiv = styled.div`
    .youtube {
        height: 32vw;
        width: 100%;
    }
    .thkim_press_logo {
        width: 100%;
    }
    .thkim_press_summary {
        font-weight: bold;
        color: rgb(51, 51, 51);
        font-size: 18px;
        padding-top: 20px;
        height: 200px;
    }
`;


const News = () => {
    return (
        <MainDiv>
            {/**Pricing */}
            <div className="section type-2">
                <div className="container">
                    <div className="section-headlines">
                    <h4>Press</h4>
                    <h2>ASTER COIN IN THE MEDIA</h2>
                    <div>Recent news, releases and publications</div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                        {/**<iframe className="youtube" src="https://www.youtube.com/embed/esFoqZrNxQw?&autoplay=1&loop=1&rel=0&showinfo=0&color=white&iv_load_policy=3&playlist=esFoqZrNxQw&controls=0&mute=1" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
                        <iframe className="youtube" src="https://www.youtube.com/embed/-e_WOqxXMGE?&autoplay=1&loop=1&rel=0&showinfo=0&color=white&iv_load_policy=3&playlist=-e_WOqxXMGE&controls=0&mute=1" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                        <hr />
                    </div>

                    {/**press 18 line START */}
                    <div className="pricing-plans row">
                        <hr />
                        
                        <div className="col-lg-4">
                            <div className="plan plan-featured radius3 thkim_press_item">
                                <div className="plan-name">
                                    <h2>
                                        <img className="thkim_press_logo" src="images/press/press_20230117_01.png?v=20220803_1348" alt="" />
                                        <div className="thkim_press_date">Jan 17, 2023</div>
                                    </h2>
                                </div>
                                <div className="plan-details">
                                    <p className="thkim_press_summary">
                                        <span className="thkim_double_quote_emphasis">'</span>
                                        <span id="press_20220919_03">메타라이브 IOS version 출시</span>
                                        <span className="thkim_double_quote_emphasis">'</span>
                                    </p>
                                </div>
                                <div className="plan-action">
                                    <a href="https://apps.apple.com/kr/app/%EB%A9%94%ED%83%80%EB%9D%BC%EC%9D%B4%EB%B8%8C/id1661587399" className="btn btn-success btn-block btn-large" target="_blank">READ MORE</a>
                                </div>
                            </div>
                            <div className="visible-xs visible-sm">
                                <br className="gap-30" />
                                <hr className="gap-divider" />
                                <br className="gap-30" />
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="plan plan-featured radius3 thkim_press_item">
                            <div className="plan-name">
                                <h2>
                                    <img className="thkim_press_logo" src="images/press/press_20230117_02.png?v=20220803_1348" alt="" />
                                    <div className="thkim_press_date">Jan 17, 2022</div>
                                </h2>
                            </div>
                            <div className="plan-details">
                                <p className="thkim_press_summary">
                                    <span className="thkim_double_quote_emphasis">'</span>
                                    <span id="press_20220919_02">전 세계 유명지를 담아내는 메타버스 개발사 '오썸피아'</span>
                                    <span className="thkim_double_quote_emphasis">'</span>
                                </p>
                            </div>
                            <div className="plan-action"> <a href="docs/press/AutoMeta_7호_v2.pdf" className="btn btn-success btn-block btn-large" target="_blank">READ MORE</a> </div>
                            </div>
                            <div className="visible-xs visible-sm"> <br className="gap-30" />
                            <hr className="gap-divider" />
                            <br className="gap-30" />
                            </div>
                        </div>
                        {
                            false &&
                            <div className="col-lg-4">
                            <div className="plan plan-featured radius3 thkim_press_item">
                            <div className="plan-name">
                                <h2>
                                    <img className="thkim_press_logo" src="images/press/press_20220919_01.png?v=20220803_1348" alt="" />
                                    <div className="thkim_press_date">Sep 19, 2022</div>
                                </h2>
                            </div>
                            <div className="plan-details">
                                <p className="thkim_press_summary">
                                <span className="thkim_double_quote_emphasis">'</span>
                                <span id="press_20220919_01">오썸피아, "메타라이브, 실사+가상 융합"… 3만여곳 여행지정보 담았다</span>
                                <span className="thkim_double_quote_emphasis">'</span>
                                </p>
                            </div>
                            <div className="plan-action"> <a href="https://n.news.naver.com/article/030/0003045058" className="btn btn-success btn-block btn-large" target="_blank">READ MORE</a> </div>
                            </div>
                            <div className="visible-xs visible-sm"> <br className="gap-30" />
                            <hr className="gap-divider" />
                            <br className="gap-30" />
                            </div>
                        </div>   
                        }
                          
                    </div>
                    {/**press 18 line END */}

                    {/**press 17 line START */}
                    <div className="pricing-plans row">
                        <hr />
                        
                        <div className="col-lg-4">
                            <div className="plan plan-featured radius3 thkim_press_item">
                                <div className="plan-name">
                                    <h2>
                                        <img className="thkim_press_logo" src="images/press/press_20220919_03.png?v=20220803_1348" alt="" />
                                        <div className="thkim_press_date">Sep 19, 2022</div>
                                    </h2>
                                </div>
                                <div className="plan-details">
                                    <p className="thkim_press_summary">
                                        <span className="thkim_double_quote_emphasis">'</span>
                                        <span id="press_20220919_03">오썸피아, "메타라이브, 실사+가상 융합"… 3만여곳 여행지정보 담았다.</span>
                                        <span className="thkim_double_quote_emphasis">'</span>
                                    </p>
                                </div>
                                <div className="plan-action">
                                    <a href="images/press/press/resources/press_20220919_03.jpg" className="btn btn-success btn-block btn-large" target="_blank">READ MORE</a>
                                </div>
                            </div>
                            <div className="visible-xs visible-sm">
                                <br className="gap-30" />
                                <hr className="gap-divider" />
                                <br className="gap-30" />
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="plan plan-featured radius3 thkim_press_item">
                            <div className="plan-name">
                                <h2>
                                    <img className="thkim_press_logo" src="images/press/press_20220919_02.png?v=20220803_1348" alt="" />
                                    <div className="thkim_press_date">Sep 19, 2022</div>
                                </h2>
                            </div>
                            <div className="plan-details">
                                <p className="thkim_press_summary">
                                    <span className="thkim_double_quote_emphasis">'</span>
                                    <span id="press_20220919_02">[미래기술 R&D 현장] 오썸피아:메타버스</span>
                                    <span className="thkim_double_quote_emphasis">'</span>
                                </p>
                            </div>
                            <div className="plan-action"> <a href="https://n.news.naver.com/article/030/0003045051" className="btn btn-success btn-block btn-large" target="_blank">READ MORE</a> </div>
                            </div>
                            <div className="visible-xs visible-sm"> <br className="gap-30" />
                            <hr className="gap-divider" />
                            <br className="gap-30" />
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="plan plan-featured radius3 thkim_press_item">
                            <div className="plan-name">
                                <h2>
                                    <img className="thkim_press_logo" src="images/press/press_20220919_01.png?v=20220803_1348" alt="" />
                                    <div className="thkim_press_date">Sep 19, 2022</div>
                                </h2>
                            </div>
                            <div className="plan-details">
                                <p className="thkim_press_summary">
                                <span className="thkim_double_quote_emphasis">'</span>
                                <span id="press_20220919_01">오썸피아, "메타라이브, 실사+가상 융합"… 3만여곳 여행지정보 담았다</span>
                                <span className="thkim_double_quote_emphasis">'</span>
                                </p>
                            </div>
                            <div className="plan-action"> <a href="https://n.news.naver.com/article/030/0003045058" className="btn btn-success btn-block btn-large" target="_blank">READ MORE</a> </div>
                            </div>
                            <div className="visible-xs visible-sm"> <br className="gap-30" />
                            <hr className="gap-divider" />
                            <br className="gap-30" />
                            </div>
                        </div>     
                    </div>
                    {/**press 17 line END */}

                    {/**press 16 line START */}
                    <div className="pricing-plans row">
                    <hr />
                    
                    <div className="col-lg-4">
                        <div className="plan plan-featured radius3 thkim_press_item">
                            <div className="plan-name">
                                <h2>
                                    <img className="thkim_press_logo" src="images/press/press_20220814_01.png?v=20220803_1348" alt="" />
                                    <div className="thkim_press_date">Aug 14, 2022</div>
                                </h2>
                            </div>
                            <div className="plan-details">
                                <p className="thkim_press_summary">
                                    <span className="thkim_double_quote_emphasis">'</span>
                                    <span id="press_20220814_01">앤디 림 템부수파트너스 회장 "탈중앙화 금융 수요 늘어날 것…투명성이 핵심"</span>
                                    <span className="thkim_double_quote_emphasis">'</span>
                                </p>
                            </div>
                            <div className="plan-action">
                                <a href="https://n.news.naver.com/article/newspaper/015/0004736773?date=20220815" className="btn btn-success btn-block btn-large" target="_blank">READ MORE</a>
                            </div>
                        </div>
                        <div className="visible-xs visible-sm">
                            <br className="gap-30" />
                            <hr className="gap-divider" />
                            <br className="gap-30" />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="plan plan-featured radius3 thkim_press_item">
                        <div className="plan-name">
                            <h2>
                                <img className="thkim_press_logo" src="images/press/press_20220720_03.png?v=20220803_1348" alt="" />
                                <div className="thkim_press_date">Jul 20, 2022</div>
                            </h2>
                        </div>
                        <div className="plan-details">
                            <p className="thkim_press_summary">
                                <span className="thkim_double_quote_emphasis">'</span>
                                <span id="press_20220720_03">오썸피아, 99억원 규모 지역특화 메타버스 서비스 개발사업 참여</span>
                                <span className="thkim_double_quote_emphasis">'</span>
                            </p>
                        </div>
                        <div className="plan-action"> <a href="https://n.news.naver.com/article/030/0003031975" className="btn btn-success btn-block btn-large" target="_blank">READ MORE</a> </div>
                        </div>
                        <div className="visible-xs visible-sm"> <br className="gap-30" />
                        <hr className="gap-divider" />
                        <br className="gap-30" />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="plan plan-featured radius3 thkim_press_item">
                        <div className="plan-name">
                            <h2>
                                <img className="thkim_press_logo" src="images/press/press_20220618_01.png?v=20220803_1348" alt="" />
                                <div className="thkim_press_date">Jun 18, 2022</div>
                            </h2>
                        </div>
                        <div className="plan-details">
                            <p className="thkim_press_summary">
                            <span className="thkim_double_quote_emphasis">'</span>
                            <span id="press_20220618_01">㈜메타렉스, 포인트 결제 시스템 선봬…"메타버스 실물 경제체계 구축 가속"</span>
                            <span className="thkim_double_quote_emphasis">'</span>
                            </p>
                        </div>
                        <div className="plan-action"> <a href="http://cnews.thebigdata.co.kr/view.php?ud=202206181921544149d0a8833aad_23" className="btn btn-success btn-block btn-large" target="_blank">READ MORE</a> </div>
                        </div>
                        <div className="visible-xs visible-sm"> <br className="gap-30" />
                        <hr className="gap-divider" />
                        <br className="gap-30" />
                        </div>
                    </div>     
                    </div>
                    {/**press 16 line END */}
                    
                    {/**press 15 line START */}
                    <div className="pricing-plans row">
                    <hr />   

                    <div className="col-lg-4">
                        <div className="plan plan-featured radius3 thkim_press_item">
                            <div className="plan-name">
                                <h2>
                                    <img className="thkim_press_logo" src="images/press/press_20220609_02.png?v=20220803_1348" alt="" />
                                    <div className="thkim_press_date">Jun 09, 2022</div>
                                </h2>
                            </div>
                            <div className="plan-details">
                                <p className="thkim_press_summary">
                                    <span className="thkim_double_quote_emphasis">'</span>
                                    <span id="press_20220609_02">ASTER (ATC) | 전략적 파트너십 체결파트너십 체결: Burpple (Burpple)</span>
                                    <span className="thkim_double_quote_emphasis">'</span>
                                </p>
                            </div>
                            <div className="plan-action">
                                <a href="https://xangle.io/project/ATC/recent-disclosure/62a1b06f35baa996641ef98e" className="btn btn-success btn-block btn-large" target="_blank">READ MORE</a>
                            </div>
                        </div>
                        <div className="visible-xs visible-sm">
                            <br className="gap-30" />
                            <hr className="gap-divider" />
                            <br className="gap-30" />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="plan plan-featured radius3 thkim_press_item">
                            <div className="plan-name">
                                <h2>
                                    <img className="thkim_press_logo" src="images/press/press_20220609_01.png?v=20220803_1348" alt="" />
                                    <div className="thkim_press_date">Jun 09, 2022</div>
                                </h2>
                            </div>
                            <div className="plan-details">
                                <p className="thkim_press_summary">
                                <span className="thkim_double_quote_emphasis">'</span>
                                <span id="press_20220609_01">㈜메타렉스, 동남아 최대 음식평가 플랫폼 ㈜버플과 맞손…"글로벌 메타 생태계 성큼"</span>
                                <span className="thkim_double_quote_emphasis">'</span>
                                </p>
                            </div>
                            <div className="plan-action"> <a href="http://cnews.thebigdata.co.kr/view.php?ud=202206091556341850d0a8833aad_23" className="btn btn-success btn-block btn-large" target="_blank">READ MORE</a> </div>
                            </div>
                            <div className="visible-xs visible-sm"> <br className="gap-30" />
                            <hr className="gap-divider" />
                            <br className="gap-30" />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="plan plan-featured radius3 thkim_press_item">
                        <div className="plan-name">
                            <h2>
                                <img className="thkim_press_logo" src="images/press/press_20220425_01.png?v=20220803_1348" alt="" />
                                <div className="thkim_press_date">Apr 26, 2022</div>
                            </h2>
                        </div>
                        <div className="plan-details">
                            <p className="thkim_press_summary">
                                <span className="thkim_double_quote_emphasis">'</span>
                                <span id="press_20220426_01">TSnet and Dream Security Co., Ltd., Korea's top certified security company, jointly cooperated in the Metaverse-Blockchain field</span>
                                <span className="thkim_double_quote_emphasis">'</span>
                            </p>
                        </div>
                        <div className="plan-action"> <a href="https://markets.ft.com/data/announce/detail?dockey=600-202204252000PR_NEWS_USPRX____CN34516-1" className="btn btn-success btn-block btn-large" target="_blank">READ MORE</a> </div>
                        </div>
                        <div className="visible-xs visible-sm"> <br className="gap-30" />
                        <hr className="gap-divider" />
                        <br className="gap-30" />
                        </div>
                    </div>

                    </div>
                    {/**press 15 line END */}

                    {/**press 14 line START */}
                    <div className="pricing-plans row">
                    <hr />      

                    <div className="col-lg-4">
                        <div className="plan plan-featured radius3 thkim_press_item">
                        <div className="plan-name">
                            <h2>
                                <img className="thkim_press_logo" src="images/press/press_20220428_01.png?v=20220803_1348" alt="" />
                                <div className="thkim_press_date">Apr 28, 2022</div>
                            </h2>
                        </div>
                        <div className="plan-details">
                            <p className="thkim_press_summary">
                            <span className="thkim_double_quote_emphasis">'</span>
                            <span id="press_20220428_01">아스터코인 비트코인 에이프코인/티에스네트·드림시큐리티…WSJ도 주목/메타버스·블록체인 공동협력/반등 주도하는 원숭이 코인(APE) 뭐길래?</span>
                            <span className="thkim_double_quote_emphasis">'</span>
                            </p>
                        </div>
                        <div className="plan-action"> <a href="https://www.youtube.com/watch?app=desktop&v=qHYMfvE9BPA&t=116s" className="btn btn-success btn-block btn-large" target="_blank">READ MORE</a> </div>
                        </div>
                        <div className="visible-xs visible-sm"> <br className="gap-30" />
                        <hr className="gap-divider" />
                        <br className="gap-30" />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="plan plan-featured radius3 thkim_press_item">
                            <div className="plan-name">
                                <h2>
                                    <img className="thkim_press_logo" src="images/press/press_20220425_02.png?v=20220803_1348" alt="" />
                                    <div className="thkim_press_date">Apr 25, 2022</div>
                                </h2>
                            </div>
                            <div className="plan-details">
                                <p className="thkim_press_summary">
                                    <span className="thkim_double_quote_emphasis">'</span>
                                    <span id="press_20220425_02">TSnet and Dream Security Co., Ltd., Korea's top certified security company, jointly cooperated in the Metaverse-Blockchain field</span>
                                    <span className="thkim_double_quote_emphasis">'</span>
                                </p>
                            </div>
                            <div className="plan-action">
                                <a href="https://www.wsj.com/articles/tsnet-and-dream-security-co-ltd-korea-s-top-certified-security-company-jointly-cooperated-in-the-metaverse-blockchain-field-01650931508?mod=Searchresults_pos1&page=1" className="btn btn-success btn-block btn-large" target="_blank">READ MORE</a>
                            </div>
                        </div>
                        <div className="visible-xs visible-sm"> <br className="gap-30" />
                        <hr className="gap-divider" />
                        <br className="gap-30" />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="plan plan-featured radius3 thkim_press_item">
                        <div className="plan-name">
                            <h2>
                                <img className="thkim_press_logo" src="images/press/press_20220425_01.png?v=20220803_1348" alt="" />
                                <div className="thkim_press_date">Apr 25, 2022</div>
                            </h2>
                        </div>
                        <div className="plan-details">
                            <p className="thkim_press_summary">
                            <span className="thkim_double_quote_emphasis">'</span>
                            <span id="press_20220425_01">TSnet and Dream Security Co., Ltd., Korea's top certified security company, jointly cooperated in the Metaverse-Blockchain field</span>
                            <span className="thkim_double_quote_emphasis">'</span>
                            </p>
                        </div>
                        <div className="plan-action"> <a href="https://markets.businessinsider.com/news/stocks/tsnet-and-dream-security-co-ltd-korea-s-top-certified-security-company-jointly-cooperated-in-the-metaverse-blockchain-field-1031381778" className="btn btn-success btn-block btn-large" target="_blank">READ MORE</a> </div>
                        </div>
                        <div className="visible-xs visible-sm"> <br className="gap-30" />
                        <hr className="gap-divider" />
                        <br className="gap-30" />
                        </div>
                    </div>

                    </div>
                    {/**press 14 line END */}

                    {/**press 13 line START */}
                    <div className="pricing-plans row">
                    <hr />      

                    <div className="col-lg-4">
                        <div className="plan plan-featured radius3 thkim_press_item">
                        <div className="plan-name">
                            <h2>
                                <img className="thkim_press_logo" src="images/press/press_20220426_01.png?v=20220803_1348" alt="" />
                                <div className="thkim_press_date">Apr 26, 2022</div>
                            </h2>
                        </div>
                        <div className="plan-details">
                            <p className="thkim_press_summary">
                            <span className="thkim_double_quote_emphasis">'</span>
                            <span id="press_20220426_01">티에스네트, ㈜드림시큐리티와 메타버스·블록체인 분야 공동협력 맞손</span>
                            <span className="thkim_double_quote_emphasis">'</span>
                            </p>
                        </div>
                        <div className="plan-action"> <a href="http://m.thebigdata.co.kr/view.php?ud=202204260854502454d0a8833aad_23" className="btn btn-success btn-block btn-large" target="_blank">READ MORE</a> </div>
                        </div>
                        <div className="visible-xs visible-sm"> <br className="gap-30" />
                        <hr className="gap-divider" />
                        <br className="gap-30" />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="plan plan-featured radius3 thkim_press_item">
                        <div className="plan-name">
                            <h2>
                                <img className="thkim_press_logo" src="images/press/press_20220421_01.png?v=20220803_1348" alt="" />
                                <div className="thkim_press_date">Apr 21, 2022</div>
                            </h2>
                        </div>
                        <div className="plan-details">
                            <p className="thkim_press_summary">
                            <span className="thkim_double_quote_emphasis">'</span>
                            <span id="press_20220421_01">전자신문-[기고]NFT의 미래는 메타버스</span>
                            <span className="thkim_double_quote_emphasis">'</span>
                            </p>
                        </div>
                        <div className="plan-action"> <a href="https://n.news.naver.com/article/newspaper/030/0003011897?date=20220422" className="btn btn-success btn-block btn-large" target="_blank">READ MORE</a> </div>
                        </div>
                        <div className="visible-xs visible-sm"> <br className="gap-30" />
                        <hr className="gap-divider" />
                        <br className="gap-30" />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="plan plan-featured radius3 thkim_press_item">
                        <div className="plan-name">
                            <h2>
                                <img className="thkim_press_logo" src="images/press/press_20220415_01.png?v=20220803_1348" alt="" />
                                <div className="thkim_press_date">Apr 15, 2022</div>
                            </h2>
                        </div>
                        <div className="plan-details">
                            <p className="thkim_press_summary">
                            <span className="thkim_double_quote_emphasis">'</span>
                            <span id="press_20220415_01">MEXC Global. 26 Questions with Aster rev</span>
                            <span className="thkim_double_quote_emphasis">'</span>
                            </p>
                        </div>
                        <div className="plan-action"> <a href="https://www.youtube.com/watch?v=VlFd-RvzlCY" className="btn btn-success btn-block btn-large" target="_blank">READ MORE</a> </div>
                        </div>
                        <div className="visible-xs visible-sm"> <br className="gap-30" />
                        <hr className="gap-divider" />
                        <br className="gap-30" />
                        </div>
                    </div>

                    </div>
                    {/**press 13 line END */}
                    

                    {/**press 12 line START */}
                    <div className="pricing-plans row">
                    <hr />
                    

                    <div className="col-lg-4">
                        <div className="plan plan-featured radius3 thkim_press_item">
                        <div className="plan-name">
                            <h2>
                                <img className="thkim_press_logo" src="images/press/press_20220402_01.png?v=20220803_1348" alt="" />
                                <div className="thkim_press_date">Apr 02, 2022</div>
                            </h2>
                        </div>
                        <div className="plan-details">
                            <p className="thkim_press_summary">
                            <span className="thkim_double_quote_emphasis">'</span>
                            <span id="press_20220402_01">메타버스 타임머신 타고 신라시대로 간다</span>
                            <span className="thkim_double_quote_emphasis">'</span>
                            </p>
                        </div>
                        <div className="plan-action"> <a href="https://n.news.naver.com/article/469/0000667173" className="btn btn-success btn-block btn-large" target="_blank">READ MORE</a> </div>
                        </div>
                        <div className="visible-xs visible-sm"> <br className="gap-30" />
                        <hr className="gap-divider" />
                        <br className="gap-30" />
                        </div>
                    </div>
                    
                    <div className="col-lg-4">
                        <div className="plan plan-featured radius3 thkim_press_item">
                        <div className="plan-name">
                            <h2>
                                <img className="thkim_press_logo" src="images/press/press_20220330_01.png?v=20220803_1348" alt="" />
                                <div className="thkim_press_date">Mar 30, 2022</div>
                            </h2>
                        </div>
                        <div className="plan-details">
                            <p className="thkim_press_summary">
                            <span className="thkim_double_quote_emphasis">'</span>
                            <span id="press_20220330_01">[K-유니콘을 찾아라] 메타버스 신흥강자 대기업 투자유치까지 오썸피아만의 매력은?</span>
                            <span className="thkim_double_quote_emphasis">'</span>
                            </p>
                        </div>
                        <div className="plan-action"> <a href="https://www.youtube.com/watch?v=uvsOf85VoY4" className="btn btn-success btn-block btn-large" target="_blank">READ MORE</a> </div>
                        </div>
                        <div className="visible-xs visible-sm"> <br className="gap-30" />
                        <hr className="gap-divider" />
                        <br className="gap-30" />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="plan plan-featured radius3 thkim_press_item">
                        <div className="plan-name">
                            <h2>
                                <img className="thkim_press_logo" src="images/press/press_20220328_01.png?v=20220803_1348" alt="" />
                                <div className="thkim_press_date">Mar 28, 2022</div>
                            </h2>
                        </div>
                        <div className="plan-details">
                            <p className="thkim_press_summary">
                            <span className="thkim_double_quote_emphasis">'</span>
                            <span id="press_20220328_01">Daehong invests in Metaverse platform company Awesomepia</span>
                            <span className="thkim_double_quote_emphasis">'</span>
                            </p>
                        </div>
                        <div className="plan-action"> <a href="https://coinhubkorea.com/daehong-invests-in-metaverse-platform-company-asomepia/" className="btn btn-success btn-block btn-large" target="_blank">READ MORE</a> </div>
                        </div>
                        <div className="visible-xs visible-sm"> <br className="gap-30" />
                        <hr className="gap-divider" />
                        <br className="gap-30" />
                        </div>
                    </div>

                    </div>
                    {/**press 12 line END */}

                    {/**press 11 line START */}
                    <div className="pricing-plans row">
                    <hr />


                    <div className="col-lg-4">
                        <div className="plan plan-featured radius3 thkim_press_item">
                        <div className="plan-name">
                            <h2>
                                <img className="thkim_press_logo" src="images/press/press_20220325_01.png?v=20220803_1348" alt="" />
                                <div className="thkim_press_date">Mar 25, 2022</div>
                            </h2>
                        </div>
                        <div className="plan-details">
                            <p className="thkim_press_summary">
                            <span className="thkim_double_quote_emphasis">'</span>
                            <span id="press_20220325_01">대홍기획, 메타버스 플랫폼 기업 오썸피아에 투자</span>
                            <span className="thkim_double_quote_emphasis">'</span>
                            </p>
                        </div>
                        <div className="plan-action"> <a href="https://n.news.naver.com/article/366/0000802002" className="btn btn-success btn-block btn-large" target="_blank">READ MORE</a> </div>
                        </div>
                        <div className="visible-xs visible-sm"> <br className="gap-30" />
                        <hr className="gap-divider" />
                        <br className="gap-30" />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="plan plan-featured radius3 thkim_press_item">
                        <div className="plan-name">
                            <h2>
                                <img className="thkim_press_logo" src="images/press/press_20220322_01.png?v=20220803_1348" alt="" />
                                <div className="thkim_press_date">Mar 22, 2022</div>
                            </h2>
                        </div>
                        <div className="plan-details">
                            <p className="thkim_press_summary">
                            <span className="thkim_double_quote_emphasis">'</span>
                            <span id="press_20220322_01">"가상 아파트 분양 경쟁률 장난 아니네" 메타버스 부동산 투자 열기</span>
                            <span className="thkim_double_quote_emphasis">'</span>
                            </p>
                        </div>
                        <div className="plan-action"> <a href="https://m.hankookilbo.com/News/Read/A2022032109060003197" className="btn btn-success btn-block btn-large" target="_blank">READ MORE</a> </div>
                        </div>
                        <div className="visible-xs visible-sm"> <br className="gap-30" />
                        <hr className="gap-divider" />
                        <br className="gap-30" />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="plan plan-featured radius3 thkim_press_item">
                        <div className="plan-name">
                            <h2>
                                <img className="thkim_press_logo" src="images/press/press_20220317_01.png?v=20220803_1348" alt="" />
                                <div className="thkim_press_date">Mar 17, 2022</div>
                            </h2>
                        </div>
                        <div className="plan-details">
                            <p className="thkim_press_summary">
                            <span className="thkim_double_quote_emphasis">'</span>
                            <span id="press_20220317_01">예고됐던 금리인상에 안도감/아스터프로젝트(ATC) 세계 20위권 거래소 MEXC 상장/알트코인 호재 – 루나·파일코인,알고랜드,플로우</span>
                            <span className="thkim_double_quote_emphasis">'</span>
                            </p>
                        </div>
                        <div className="plan-action"> <a href="https://www.youtube.com/watch?v=dYwxXv6BOxQ&t=23s" className="btn btn-success btn-block btn-large" target="_blank">READ MORE</a> </div>
                        </div>
                        <div className="visible-xs visible-sm"> <br className="gap-30" />
                        <hr className="gap-divider" />
                        <br className="gap-30" />
                        </div>
                    </div>

                    </div>
                    {/**press 11 line END */}

                    {/**press 10 line START */}
                    <div className="pricing-plans row">
                    <hr />

                    <div className="col-lg-4">
                        <div className="plan plan-featured radius3 thkim_press_item">
                        <div className="plan-name">
                            <h2>
                                <img className="thkim_press_logo" src="images/press/press_20220316_06.png?v=20220803_1348" alt="" />
                                <div className="thkim_press_date">Mar 16, 2022
                                </div>
                            </h2>
                        </div>
                        <div className="plan-details">
                            <p className="thkim_press_summary">
                            <span className="thkim_double_quote_emphasis">'</span>
                            <span id="press_20220316_06">아스터프로젝트(ATC), 세계 20위권 거래소 MEXC 상장 - 넥스트데일리</span>
                            <span className="thkim_double_quote_emphasis">'</span>
                            </p>
                        </div>
                        <div className="plan-action"> <a href="http://www.nextdaily.co.kr/news/articleView.html?idxno=204030" className="btn btn-success btn-block btn-large" target="_blank">READ MORE</a> </div>
                        </div>
                        <div className="visible-xs visible-sm"> <br className="gap-30" />
                        <hr className="gap-divider" />
                        <br className="gap-30" />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="plan plan-featured radius3 thkim_press_item">
                        <div className="plan-name">
                            <h2>
                                <img className="thkim_press_logo" src="images/press/press_20220316_05.png?v=20220803_1348" alt="" />
                                <div className="thkim_press_date">Mar 16, 2022
                                </div>
                            </h2>
                        </div>
                        <div className="plan-details">
                            <p className="thkim_press_summary">
                            <span className="thkim_double_quote_emphasis">'</span>
                            <span id="press_20220316_05">아스터코인(ATC), 드디어 MEXC글로벌거래소에 상장확정!</span>
                            <span className="thkim_double_quote_emphasis">'</span>
                            </p>
                        </div>
                        <div className="plan-action"> <a href="https://blog.naver.com/rlarnrxo2959/222674400236" className="btn btn-success btn-block btn-large" target="_blank">READ MORE</a> </div>
                        </div>
                        <div className="visible-xs visible-sm"> <br className="gap-30" />
                        <hr className="gap-divider" />
                        <br className="gap-30" />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="plan radius3 thkim_press_item">
                        <div className="plan-name">
                            <h2>
                            <img className="thkim_press_logo" src="images/press/press_20220316_04.png?v=20220803_1348" alt="" />
                            <div className="thkim_press_date">Mar 16, 2022
                                </div>
                            </h2>
                        </div>
                        <div className="plan-details">
                            <p className="thkim_press_summary">
                            <span className="thkim_double_quote_emphasis">'</span>
                            <span id="press_20220316_04">MEXC Kickstarter - Bỏ phiếu để giành được 3,012,912 Aster (ATC) miễn phí!</span>
                            <span className="thkim_double_quote_emphasis">'</span>
                            </p>
                        </div>
                        <div className="plan-action"> <a href="https://support.mexc.com/hc/vi/articles/4860179926425" className="btn btn-inverse btn-block btn-large" target="_blank">READ MORE</a> </div>
                        </div>
                        <div className="visible-xs visible-sm"> <br className="gap-30" />
                        <hr className="gap-divider" />
                        <br className="gap-30" />
                        </div>
                    </div>
                    
                    </div>
                    {/**press 10 line END */}

                    {/**press 9 line START */}
                    <div className="pricing-plans row">
                    <hr />

                    <div className="col-lg-4">
                        <div className="plan plan-featured radius3 thkim_press_item">
                        <div className="plan-name">
                            <h2>
                                <img className="thkim_press_logo" src="images/press/press_20220316_03.png?v=20220803_1348" alt="" />
                                <div className="thkim_press_date">Mar 16, 2022
                                </div>
                            </h2>
                        </div>
                        <div className="plan-details">
                            <p className="thkim_press_summary">
                            <span className="thkim_double_quote_emphasis">'</span>
                            <span id="press_20220316_03">MEXC Aster (ATC)を手に入れよう！Kickstarterキャンペーン開催のお知らせ</span>
                            <span className="thkim_double_quote_emphasis">'</span>
                            </p>
                        </div>
                        <div className="plan-action"> <a href="https://support.mexc.com/hc/ja/articles/4860179926425" className="btn btn-success btn-block btn-large" target="_blank">READ MORE</a> </div>
                        </div>
                        <div className="visible-xs visible-sm"> <br className="gap-30" />
                        <hr className="gap-divider" />
                        <br className="gap-30" />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="plan plan-featured radius3 thkim_press_item">
                        <div className="plan-name">
                            <h2>
                                <img className="thkim_press_logo" src="images/press/press_20220316_02.png?v=20220803_1348" alt="" />
                                <div className="thkim_press_date">Mar 16, 2022
                                </div>
                            </h2>
                        </div>
                        <div className="plan-details">
                            <p className="thkim_press_summary">
                            <span className="thkim_double_quote_emphasis">'</span>
                            <span id="press_20220316_02">MEXC, “Kickstarter” - 투표하여 무료 에어드랍 Aster (ATC)</span>
                            <span className="thkim_double_quote_emphasis">'</span>
                            </p>
                        </div>
                        <div className="plan-action"> <a href="https://support.mexc.com/hc/ko-kr/articles/4860179926425" className="btn btn-success btn-block btn-large" target="_blank">READ MORE</a> </div>
                        </div>
                        <div className="visible-xs visible-sm"> <br className="gap-30" />
                        <hr className="gap-divider" />
                        <br className="gap-30" />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="plan radius3 thkim_press_item">
                        <div className="plan-name">
                            <h2>
                            <img className="thkim_press_logo" src="images/press/press_20220316_01.png?v=20220803_1348" alt="" />
                            <div className="thkim_press_date">Mar 16, 2022
                                </div>
                            </h2>
                        </div>
                        <div className="plan-details">
                            <p className="thkim_press_summary">
                            <span className="thkim_double_quote_emphasis">'</span>
                            <span id="press_20220316_01">MEXC Kickstarter - Vote to Win Free 3,012,912 Aster (ATC) Airdrops!</span>
                            <span className="thkim_double_quote_emphasis">'</span>
                            </p>
                        </div>
                        <div className="plan-action"> <a href="https://support.mexc.com/hc/en-001/articles/4860179926425" className="btn btn-inverse btn-block btn-large" target="_blank">READ MORE</a> </div>
                        </div>
                        <div className="visible-xs visible-sm"> <br className="gap-30" />
                        <hr className="gap-divider" />
                        <br className="gap-30" />
                        </div>
                    </div>
                    
                    </div>
                    {/**press 9 line END */}

                    {/**press 8 line START */}
                    <div className="pricing-plans row">
                    <hr />

                    <div className="col-lg-4">
                        <div className="plan radius3 thkim_press_item">
                        <div className="plan-name">
                            <h2>
                            <img className="thkim_press_logo" src="images/press/press_20220303_01.png?v=20220803_1348" alt="" />
                            <div className="thkim_press_date">Mar 03, 2022
                                </div>
                            </h2>
                        </div>
                        <div className="plan-details">
                            <p className="thkim_press_summary">
                            <span className="thkim_double_quote_emphasis">'</span><span id="press_20220303_01">Attracting new investment (tokens)</span><span className="thkim_double_quote_emphasis">'</span>
                            </p>
                        </div>
                        <div className="plan-action"> <a href="https://xangle.io/project/ATC/recent-disclosure/62202d47f02daf64455f9ad0?utm_source=telegram&utm_medium=organic_social&utm_campaign=disclosure_push" className="btn btn-inverse btn-block btn-large" target="_blank">READ MORE</a> </div>
                        </div>
                        <div className="visible-xs visible-sm"> <br className="gap-30" />
                        <hr className="gap-divider" />
                        <br className="gap-30" />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="plan radius3 thkim_press_item">
                        <div className="plan-name">
                            <h2>
                            <img className="thkim_press_logo" src="images/press/press_20220111_01.png?v=20220803_1348" alt="" />
                            <div className="thkim_press_date">Jan 11, 2022
                                </div>
                            </h2>
                        </div>
                        <div className="plan-details">
                            <p className="thkim_press_summary">
                                <span className="thkim_double_quote_emphasis">'</span>
                                <span id="press_20220111_01">Launched Korean-style Earth 2 (Metaverse Virtual Real Estate Exchange) ‘Metarex’</span>
                                <span className="thkim_double_quote_emphasis">'</span>
                            </p>
                        </div>
                        <div className="plan-action"> <a href="https://coinhubkorea.com/opened-korean-style-earth-2-metaverse-virtual-real-estate-exchange-metalex/" className="btn btn-inverse btn-block btn-large" target="_blank">READ MORE</a> </div>
                        </div>
                        <div className="visible-xs visible-sm"> <br className="gap-30" />
                        <hr className="gap-divider" />
                        <br className="gap-30" />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="plan plan-featured radius3 thkim_press_item">
                        <div className="plan-name">
                            <h2>
                                <img className="thkim_press_logo" src="images/press/press_20220119_01.png?v=20220803_1348" alt="" />
                                <div className="thkim_press_date">Jan 19, 2022
                                </div>
                            </h2>
                        </div>
                        <div className="plan-details">
                            <p className="thkim_press_summary">
                            <span className="thkim_double_quote_emphasis">'</span><span id="press_20220119_01">South Korea Metaverse Crypto Coins Today</span><span className="thkim_double_quote_emphasis">'</span>
                            </p>
                        </div>
                        <div className="plan-action"> <a href="https://coinhubkorea.com/south-korea-metaverse-crypto-coins-today/" className="btn btn-success btn-block btn-large" target="_blank">READ MORE</a> </div>
                        </div>
                        <div className="visible-xs visible-sm"> <br className="gap-30" />
                        <hr className="gap-divider" />
                        <br className="gap-30" />
                        </div>
                    </div>

                    
                    </div>
                    {/**press 8 line END */}

                        {/**press 6 line START */}
                        <div className="pricing-plans row">
                        <hr />

                        <div className="col-lg-4">
                            <div className="plan radius3 thkim_press_item">
                            <div className="plan-name">
                                <h2>
                                <img className="thkim_press_logo" src="images/press/press_20210930_01.png?v=20220803_1348" alt="" />
                                <div className="thkim_press_date">Sep 30, 2021
                                    </div>
                                </h2>
                            </div>
                            <div className="plan-details">
                                <p className="thkim_press_summary">
                                    <span className="thkim_double_quote_emphasis">'</span><span id="press_20210930_01">LG헬로비전, 오썸피아와 스마트문화관광 위한 업무협약 체결
                                    </span><span className="thkim_double_quote_emphasis">'</span>
                                </p>
                            </div>
                            <div className="plan-action"> <a href="https://n.news.naver.com/article/030/0002972369" className="btn btn-inverse btn-block btn-large" target="_blank">READ MORE</a> </div>
                            </div>
                            <div className="visible-xs visible-sm"> <br className="gap-30" />
                            <hr className="gap-divider" />
                            <br className="gap-30" />
                            </div>
                        </div>
                        
                        <div className="col-lg-4">
                            <div className="plan radius3 thkim_press_item">
                            <div className="plan-name">
                                <h2>
                                <img className="thkim_press_logo" src="images/press/press_20210714_01.png?v=20220803_1348" alt="" />
                                <div className="thkim_press_date">Jul 14, 2021
                                    </div>
                                </h2>
                            </div>
                            <div className="plan-details">
                                <p className="thkim_press_summary">
                                    <span className="thkim_double_quote_emphasis">'</span><span id="press_20210714_01">Milestone achievement: Aster opens MetaRex, a Korean Earth-2 real estate service for the metaverse</span><span className="thkim_double_quote_emphasis">'</span>
                                </p>
                            </div>
                            <div className="plan-action"> <a href="https://xangle.io/project/ATC/recent-disclosure/60ee926537b0f42099b17804?utm_source=xangle_disclosure&utm_medium=telegram&utm_campaign=telegram_bot_send_message" className="btn btn-inverse btn-block btn-large" target="_blank">READ MORE</a> </div>
                            </div>
                            <div className="visible-xs visible-sm"> <br className="gap-30" />
                            <hr className="gap-divider" />
                            <br className="gap-30" />
                            </div>
                        </div>
                    
                        
                        </div>
                        {/**press 6 line END */}


                    {/**press 5 line START */}
                    <div className="pricing-plans row">
                    <hr />

                    <div className="col-lg-4">
                        <div className="plan plan-featured radius3 thkim_press_item">
                        <div className="plan-name">
                            <h2>
                                <img className="thkim_press_logo" src="images/press/press_20210714_02.png?v=20220803_1348" alt="" />
                                <div className="thkim_press_date">Jul 14, 2021
                                </div>
                            </h2>
                        </div>
                        <div className="plan-details">
                            <p className="thkim_press_summary">
                            <span className="thkim_double_quote_emphasis">'</span><span id="press_20210714_02">티에스네트, 메타버스 가상부동산 플랫폼 한국형 어스2 '메타렉스' 출시</span><span className="thkim_double_quote_emphasis">'</span>
                            </p>
                        </div>
                        <div className="plan-action"> <a href="https://www.datanews.co.kr/news/article.html?no=113967" className="btn btn-success btn-block btn-large" target="_blank">READ MORE</a> </div>
                        </div>
                        <div className="visible-xs visible-sm"> <br className="gap-30" />
                        <hr className="gap-divider" />
                        <br className="gap-30" />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="plan radius3 thkim_press_item">
                        <div className="plan-name">
                            <h2>
                            <img className="thkim_press_logo" src="images/press/press_20210714_03.png?v=20220803_1348" alt="" />
                            <div className="thkim_press_date">Jul 15, 2021
                                </div>
                            </h2>
                        </div>
                        <div className="plan-details">
                            <p className="thkim_press_summary">
                            <span className="thkim_double_quote_emphasis">'</span><span id="press_20210714_03">세계 최초 SNS 개발자의 코인리포트/메타버스 부동산이란?/메타버스 부동산 투자방법과 한국의 플랫폼은?</span><span className="thkim_double_quote_emphasis">'</span>
                            </p>
                        </div>
                        <div className="plan-action"> <a href="https://www.youtube.com/watch?v=ZvZA8UfUee4" className="btn btn-inverse btn-block btn-large" target="_blank">READ MORE</a> </div>
                        </div>
                        <div className="visible-xs visible-sm"> <br className="gap-30" />
                        <hr className="gap-divider" />
                        <br className="gap-30" />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="plan radius3 thkim_press_item">
                        <div className="plan-name">
                            <h2>
                            <img className="thkim_press_logo" src="images/press/press_20210326_01.png?v=20220803_1348" alt="" />
                            <div className="thkim_press_date">Mar 26, 2021
                                </div>
                            </h2>
                        </div>
                        <div className="plan-details">
                            <p className="thkim_press_summary">
                                <span className="thkim_double_quote_emphasis">'</span><span id="press_20210326_01">세계최초 SNS 개발자가 진단하는 코인 슈퍼 호재 세 가지 - 'NFT', '금리인상', '결제 관련 코인'</span><span className="thkim_double_quote_emphasis">'</span>
                            </p>
                        </div>
                        <div className="plan-action"> <a href="https://www.youtube.com/watch?v=aTnjm2LPu-w" className="btn btn-inverse btn-block btn-large" target="_blank">READ MORE</a> </div>
                        </div>
                        <div className="visible-xs visible-sm"> <br className="gap-30" />
                        <hr className="gap-divider" />
                        <br className="gap-30" />
                        </div>
                    </div>

                    
                    </div>
                    {/**press 5 line END */}

                    {/**press 4 line START */}
                    <div className="pricing-plans row">
                    <hr />

                    <div className="col-lg-4">
                        <div className="plan plan-featured radius3 thkim_press_item">
                        <div className="plan-name">
                            <h2>
                                <img className="thkim_press_logo" src="images/press/press_20210412_01.png?v=20220803_1348" alt="" />
                                <div className="thkim_press_date">Apr 12, 2021
                                </div>
                            </h2>
                        </div>
                        <div className="plan-details">
                            <p className="thkim_press_summary">
                            <span className="thkim_double_quote_emphasis">'</span><span id="press_20210412_01">㈜텐스페이스, ㈜오썸피아와 ‘힐링투어 메타버스’ 협약체결</span><span className="thkim_double_quote_emphasis">'</span>
                            </p>
                        </div>
                        <div className="plan-action"> <a href="https://n.news.naver.com/article/025/0003092793" className="btn btn-success btn-block btn-large" target="_blank">READ MORE</a> </div>
                        </div>
                        <div className="visible-xs visible-sm"> <br className="gap-30" />
                        <hr className="gap-divider" />
                        <br className="gap-30" />
                        </div>
                    </div>
                    
                    <div className="col-lg-4">
                        <div className="plan radius3 thkim_press_item">
                        <div className="plan-name">
                            <h2>
                            <img className="thkim_press_logo" src="images/press/press_20210413_01.png?v=20220803_1348" alt="" />
                            <div className="thkim_press_date">Apr 13, 2021
                                </div>
                            </h2>
                        </div>
                        <div className="plan-details">
                            <p className="thkim_press_summary">
                            <span className="thkim_double_quote_emphasis">'</span><span id="press_20210413_01">메타버스 관광과 결합하는 NFT…텐스페이스·오썸피아 맞손</span><span className="thkim_double_quote_emphasis">'</span>
                            </p>
                        </div>
                        <div className="plan-action"> <a href="https://n.news.naver.com/article/018/0004900153" className="btn btn-inverse btn-block btn-large" target="_blank">READ MORE</a> </div>
                        </div>
                        <div className="visible-xs visible-sm"> <br className="gap-30" />
                        <hr className="gap-divider" />
                        <br className="gap-30" />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="plan radius3 thkim_press_item">
                        <div className="plan-name">
                            <h2>
                            <img className="thkim_press_logo" src="images/press/press_20210414_01.png?v=20220803_1348" alt="" />
                            <div className="thkim_press_date">Apr 14, 2021
                                </div>
                            </h2>
                        </div>
                        <div className="plan-details">
                            <p className="thkim_press_summary">
                                <span className="thkim_double_quote_emphasis">'</span><span id="press_20210414_01">315조 메타버스와 접목…진격의 NFT, 기업들 뛰어든다</span><span className="thkim_double_quote_emphasis">'</span>
                            </p>
                        </div>
                        <div className="plan-action"> <a href="https://n.news.naver.com/article/newspaper/018/0004901003?date=20210414" className="btn btn-inverse btn-block btn-large" target="_blank">READ MORE</a> </div>
                        </div>
                        <div className="visible-xs visible-sm"> <br className="gap-30" />
                        <hr className="gap-divider" />
                        <br className="gap-30" />
                        </div>
                    </div>
                    </div>
                    {/**press 4 line END */}

                    {/**press 3 line START */}
                    <div className="pricing-plans row">
                    <hr />
                    
                    <div className="col-lg-4">
                        <div className="plan radius3 thkim_press_item">
                        <div className="plan-name">
                            <h2>
                            <img className="thkim_press_logo" src="images/press/press_20200424_01.png?v=20220803_1348" alt="" />
                            <div className="thkim_press_date">Apr 24, 2020
                                </div>
                            </h2>
                        </div>
                        <div className="plan-details">
                            <p className="thkim_press_summary">
                            <span className="thkim_double_quote_emphasis">'</span><span id="press_20200424_01">Cool Vendors in AI for Banking and Investment Services</span><span className="thkim_double_quote_emphasis">'</span>
                            </p>
                        </div>
                        <div className="plan-action"> <a href="https://www.gartner.com/en/documents/3984159" className="btn btn-inverse btn-block btn-large" target="_blank">READ MORE</a> </div>
                        </div>
                        <div className="visible-xs visible-sm"> <br className="gap-30" />
                        <hr className="gap-divider" />
                        <br className="gap-30" />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="plan plan-featured radius3 thkim_press_item">
                        <div className="plan-name">
                            <h2>
                                <img className="thkim_press_logo" src="images/press/press_20200515_01.png?v=20220803_1348" alt="" />
                                <div className="thkim_press_date">MAY 15, 2020
                                </div>
                            </h2>
                        </div>
                        <div className="plan-details">
                            <p className="thkim_press_summary">
                            <span className="thkim_double_quote_emphasis">'</span><span id="press_20200515_01">텐스페이스 ‘2020 가트너 뱅킹·투자분야 AI 쿨벤더’선정</span><span className="thkim_double_quote_emphasis">'</span>
                            </p>
                        </div>
                        <div className="plan-action"> <a href="https://www.edaily.co.kr/news/read?newsId=02617446625769248&mediaCodeNo=257&OutLnkChk=Y" className="btn btn-success btn-block btn-large" target="_blank">READ MORE</a> </div>
                        </div>
                        <div className="visible-xs visible-sm"> <br className="gap-30" />
                        <hr className="gap-divider" />
                        <br className="gap-30" />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="plan radius3 thkim_press_item">
                        <div className="plan-name">
                            <h2>
                            <img className="thkim_press_logo" src="images/press/press_20191010_01.png?v=20220803_1348" alt="" />
                            <div className="thkim_press_date">OCT 10, 2019
                                </div>
                            </h2>
                        </div>
                        <div className="plan-details">
                            <p className="thkim_press_summary">
                            <span className="thkim_double_quote_emphasis">'</span><span id="press_20191010_01">[2019 투자포럼] 고진석 텐스페이스 “기업이 주도하는 암호화폐 시대 온다”</span><span className="thkim_double_quote_emphasis">'</span>
                            </p>
                        </div>
                        <div className="plan-action"> <a href="https://biz.chosun.com/site/data/html_dir/2019/10/10/2019101001968.html" className="btn btn-inverse btn-block btn-large" target="_blank">READ MORE</a> </div>
                        </div>
                        <div className="visible-xs visible-sm"> <br className="gap-30" />
                        <hr className="gap-divider" />
                        <br className="gap-30" />
                        </div>
                    </div>
                    </div>
                    {/**press 3 line END */}
                </div>
            </div>
        </MainDiv>
    )
}


export default News;