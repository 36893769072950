import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { t } from "i18next";


const PaddingDiv = styled.div`
    padding: 20px;
`;

const ModalDiv = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0px;
    top: 0px;
    background: rgba(0,0,0,0.7);
    padding: 0px;
    z-index:1000;

    .popup-image {
        width: 100%;
        cursor: pointer;
    }
    .botom-right-no-display-in-today-check-box-wrapper {
        bottom: 0px;
        right: 0px;
        padding: 5px;
        text-align: right;

        & * {
            cursor: pointer;
        }

    }

    .children-wrapper {
        height: auto;
        padding: 5px;
        .children {
            overflow-y: auto;
            padding: 15px;
            max-height: 84vh;
        }        
    }

`;

const ContentDiv = styled.div`
    width: 60vw;
    min-width: 320px; 
    _min-height: 568px;
    background: rgba(255,255,255);
    padding: 0px;
    margin: 0px auto;
    position: relative;
    height: auto;
    .popup-20230508-hanbitco-delisting {
        ol {
            list-style-type: decimal;
            li  {
                margin-left: 15px;
                list-style-type: decimal;
                padding-top: 14px;
            }
        }
    }
    
`;


const CloseBtnSpan = styled.span`
    position: absolute;
    top: 10px;
    right: 10px;
    width: 27px;
    height: 27px;
    cursor: pointer;
    &::before {
        content: ' ';
        position: absolute;
        width: 27px;
        height: 1px;
        top: calc(50% - 1px);
        background: #000;
        transform-origin: center;
        transition: .3s;
        transform: rotate(45deg);
    }
    &::after{
        content: ' ';
        position: absolute;
        width: 27px;
        height: 1px;
        background: #000;
        top: calc(50% - 1px);
        transform-origin: center;
        transition: .3s;
        transform: rotate(-45deg);
    }

    &:hover {
        &::before {
            transform: rotate(0deg);
        }
        &::after{
            transform: rotate(-0deg);
        }
    }

`;

const TitleDiv = styled.div`
    color: #444;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    padding: 20px 0px;
    border-bottom: 3px solid #DFE1E5;
`;

const NoticePopup = ({
    setIsPopupShow,
    popupTitle,
    popupImageUrl,
    popupImageClickLink,
    popupImageClickLinkTarget,
    showsHidingCheckBoxForADay,
    children}) => {


    const closeModal = (e) => {
        e.preventDefault();
        setIsPopupShow(false);
    }

    const onClickPreviusButton = () => {
    }

    const onClickPopupImage = () => {
        window.open(popupImageClickLink, popupImageClickLinkTarget);
    }

    const onChangeEventBotomRightNoDisplayInTodayCheckBox = (e) => {
        if( e.target.checked ) {
            setCookie("isEventPopup20220614Disabled", "true");
        } else {
            setCookie("isEventPopup20220614Disabled", "false");
        }
    }

    function setCookie(name, value) {
        var todayDate = new Date();
        todayDate.setHours( 24 );
        document.cookie = name+ "=" + escape( value ) + "; path=/; expires=" +   todayDate.toGMTString() + ";";
    }


    useEffect(() => {
        return () => {
        }
    }, [])


    return (
        <ModalDiv>
            <ContentDiv>
                <CloseBtnSpan onClick={closeModal}/>
                <TitleDiv>
                    {popupTitle}
                </TitleDiv>
                {
                    popupImageUrl &&
                    <div className="popup-image-wrapper">
                        <img className="popup-image" src={popupImageUrl} onClick={onClickPopupImage}/>
                    </div>
                }
                <div className="children-wrapper">
                    <div className="children">
                        {children}
                    </div>
                </div>
                {showsHidingCheckBoxForADay &&
                    <div className="botom-right-no-display-in-today-check-box-wrapper">
                        <label>오늘하루이창 열지 않기<input className="botom-right-no-display-in-today-check-box" type="checkbox" onChange={onChangeEventBotomRightNoDisplayInTodayCheckBox}/></label>
                    </div>
                }          
            </ContentDiv>
        </ModalDiv>
    )
}

export default NoticePopup;
