import React, { useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { moveTopOnPage } from "../../common/CommonFunc";
import ContactForm from "./ContactForm";

const StyledContactUsTemplate = styled.div`
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 140px;
    @media only screen and (max-width: 768px) {
        padding: 140px 20px 0px 20px;
        height: auto;
    }
`;

const StyledContactUsTitle = styled.h1`
    font-family: 'Nanum Gothic', 'Roboto', 'Noto Sans SC', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;
    color: #05141F;
    @media only screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 24px;
    }
`;


const ContactUs = () => {
    const {t} = useTranslation();
    useEffect(() => {
        moveTopOnPage();
    }, []);

    return (
        <StyledContactUsTemplate>
            <StyledContactUsTitle>{t("contact_us_title")}</StyledContactUsTitle>
            <ContactForm t={t}/>
        </StyledContactUsTemplate>
    )
}

export default ContactUs;