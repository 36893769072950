import React from "react";
import { t } from "i18next";
import styled from "styled-components";

const MainDiv = styled.div`

    @keyframes spin {
        0%  {-webkit-transform: rotate(0deg);}
        100% {-webkit-transform: rotate(360deg);}
    }

    @keyframes gear_spin {
        0%  {-webkit-transform: rotate(58deg);}
        100% {-webkit-transform: rotate(418deg);}
    }

    @keyframes gear_spin_reverse {
        0%  {-webkit-transform: rotate(360deg);}
        100% {-webkit-transform: rotate(0deg);}
    }


    @media screen and (max-width:786px) {
    } 
  
  
      .section.type-2.thkim_about_area {
        background-image: url(images/about_background.png);
        background-size: 60%;
        background-repeat: no-repeat;
        background-position: right top;
  
      }
  
      @import url('https://fonts.googleapis.com/css?family=Gothic+A1:100&subset=korean');
      .thkim_about_string {
        overflow-wrap: anywhere;
        padding: 80px 0px;
        font-size: 24px;
        font-weight: 100;
        line-height: 2.2em;
        color: #888;
      }
  
      .thkim_about_string .thkim_about_string_emphasis {
        font-size: 32px;
        color: #333;
      }
  
      .thkim_about_spin_wrapper {
        position: relative;      
        height: 382px;
      }
  
      .thkim_about_spin_wrapper img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 382px;
        height: 382px;
        margin-top: -191px; /* Half the height */
        margin-left: -191px; /* Half the width */ 
      }
  
      .thkim_spin {
        -webkit-animation: spin 6s infinite linear;
      }
    
      .thkim_works {
        background-image: url(images/works_pc_background.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        height: 420px;
        text-align: center;
        position: relative;
      }  
    
  
      .thkim_works_bottom_button_item {
        display: inline-block;
        position: relative;
        color: #ffffff;
        font-weight: bold;
        font-size: 28px;
        padding: 8px;
        cursor: pointer;
      }
    
  
      .thkim_works_bottom_button {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        -webkit-appearance: none;
        -webkit-border-radius: 0;
        border: 0px solid #ffffff;
        
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
        -webkit-filter: grayscale(1) brightness(40%);
        filter: grayscale(1) brightness(40%);      
        -webkit-transition:all 0.7s, -webkit-transform 0.7s;
        transition:all 0.7s, transform 0.7s;
      }
  
      .thkim_works_bottom_button_label {
        display: inline-block;
        position: absolute;
        left: 0px;
        top: 40px;
        @media screen and (max-width:786px) {
            top: 28px;
        }
      }
  
      .thkim_works_bottom_button_item, .thkim_works_bottom_button_label {
        width: 320px;
        height: 124px;
        @media screen and (max-width:786px) {
            width: 100%;
            max-width: 300px;
            height: 100px;
        }
      }
  
  
      .thkim_works_bottom_button_item:hover .thkim_works_bottom_button {
        -webkit-filter: grayscale(0) brightness(100%);
        filter: grayscale(0) brightness(100%);      
      }
      .thkim_works_bottom_button_item:hover .thkim_works_bottom_button_label {
        text-shadow: 1px 1px 1px #333;
      }
  
      .works_bottom_button_01_aster_analysis {
        background-image: url(images/works_bottom_button_01_aster_analysis.png);
        background-size: cover;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        -webkit-appearance: none;
        -webkit-border-radius: 0;
        border: 0px solid #ffffff;
        color: #ffffff;
        font-weight: bold;
        font-size: 28px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
        -webkit-filter: grayscale(1) brightness(40%);
        filter: grayscale(1) brightness(40%);      
        -webkit-transition:all 0.7s, -webkit-transform 0.7s;
        transition:all 0.7s, transform 0.7s;
      }
  
      .works_bottom_button_02_HR {
        background-image: url(images/works_bottom_button_02_HR.jpg);
      }
  
      .works_bottom_button_03_Travel {
        background-image: url(images/works_bottom_button_03_Travel.jpg);
      }
  
      .works_bottom_button_04_Finance {
        background-image: url(images/works_bottom_button_04_Finance.jpg);
      }
  
      .works_bottom_button_05_Shopping {
        background-image: url(images/works_bottom_button_05_Shopping.jpg);
      }
  
      .works_bottom_button_06_Entertainment {
        background-image: url(images/works_bottom_button_06_Entertainment.jpg);
      }
  
      .works_bottom_button_07_Beauty {
        background-image: url(images/works_bottom_button_07_Beauty.jpg);
      }
      
  
      .thkim_works_bottom_button_wrapper {
        text-align: center;
      }
  
  
  
      .partner-body {
        margin-bottom: 30px;
      }
  
      .history-title {
        margin-top: 60px;
        padding-bottom: 20px;
      }
  
      .history-date {
        display: inline-block;
        width: 70px;
        @media (max-width: 767px) {
          color: #0095ff;
          display: block;
          padding-bottom: 5px;
        }
      }
  
  
      @media screen and (min-width: 1200px) {
        .centered {
          position: absolute;
          z-index: -1;
          top: 300px;
          left:0px;
        }
      }
  
      @media screen and (max-width: 1199px) {
  
        .thkim_display_pc_only {
          display: none;
        }  
  
      }
  
      @media (max-width: 767px) {  
      }
        
      @media (min-width: 415px) and (max-width: 991px) {
        .section {
          padding: 70px 0;
        }  
      }
  
      @media screen and (max-width: 414px) {
  
        .thkim_no_padding_bottom_mobile {
          padding-bottom: 0px;
        }
  
        .thkim_about_string {
          padding: 0px;
          font-size: 18px;
          line-height: 1.8em;
        }
  
        .thkim_about_string .thkim_about_string_emphasis {
          font-size: 26px;
        }
  
        .thkim_about_spin_wrapper {        
          margin-top: 20px;
          height: 320px;
          overflow:hidden;
        }
  
        .thkim_about_spin_wrapper img {
          width: 280px;
          height: 280px;
          margin-top: -140px; /* Half the height */
          margin-left: -140px; /* Half the width */
        }
    
  
      }
`;


const Services = () => {
    return (
        <MainDiv>
            {/**Services */}
            <div className="section type-2 thkim_no_padding_bottom_mobile thkim_about_area"  >
                <div className="container" >
                    <div className="section-headlines" >
                        <h4 >About</h4>
                    </div>
                    <div className="row"  >
                        <div id="thkim_about_string" className="col-lg-6 features thkim_about_string" dangerouslySetInnerHTML={
                        {__html: t('span_class_thkim_about_string_emphasis_astercoin_span_consists_of_an_ecosystem_that_combines_span_class_thkim_about_string_emphasis_blockchain_and_virtual_real_estate_with_the_metaverse_span_as_the_center', {interpolation: {escapeValue: false}})}
                        } />
                        <div className="col-lg-6 features text-center thkim_about_spin_wrapper">
                            <img src="images/pic3-382x382_about.png?v=20220803_1348" alt="" />
                            <img className="thkim_spin" src="images/pic3-382x382_about_spin.png?v=0000" alt="" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8">
                            <h5 className="partner-subject" id="partner-001-subject">Blockchain Partner</h5>
                            <p className="partner-body" id="partner-001-body">
                            Tenspace(tenspace.co.kr) is a company that analyzes borrowers' social media and makes microloans. Last year, Tenspace borrowed $1 million from Korea. This year, we will provide credit analysis services like a credit rating agency approved by the Korean government. When borrowing, Astercoin is paid. Interest can be repaid with Aster. And the loan will be executed next year with the coin as collateral. 
                            </p>
                        </div>
                        <div className="col-lg-8">
                            <h5 className="partner-subject" id="partner-002-subject">Metaverse Partner</h5>
                            <p className="partner-body" id="partner-002-body">
                            Asomepia (eng.awesomepia.kr) operates 'Meta Live', a metaverse platform that broadcasts real-time information through the Bora telescope. The Korean version will be released in May. A worldwide version will be released next year. 
                            Lotte , one of Korea's five largest hotel and catering companies, has invested in it. It is also conducting a joint project with a large Korean company, LG. It provides services like the local self governing government of Korea.          
                            </p>
                        </div>
                        <div className="col-lg-8" >
                            <h5 className="partner-subject" id="partner-003-subject">Virtual Real-Estate Partner</h5>
                            <p className="partner-body" id="partner-003-body">
                            Metarex (metarex.global) is the first virtual real estate exchange linked to the real economy in Korea. It had $10 million in sales last year and expects to make $50 million this year.
                            Real estate sold in Metarex is implemented in MetaLive, metaverse platform. 
                            Aster is used as the base currency on both platforms, MetaLive, Metarex.          
                            </p>
                        </div>

                        <div className="col-lg-8">
                            <h3 className="history-title">Aster coin History</h3>
                        </div>      
                        <div className="col-lg-8">
                            <a className="history-link" target="_blank" href="https://coinhubkorea.com/daehong-invests-in-metaverse-platform-company-asomepia/">
                                <h5 className="history-subject"><span className="history-date">2022.3</span> <span id="history-2022-3-02">Daehong invests in Metaverse platform company Awesomepia</span></h5>
                            </a>
                        </div>
                        <div className="col-lg-8">
                            <a className="history-link" target="_blank" href="https://xangle.io/project/ATC/recent-disclosure/62202d47f02daf64455f9ad0?utm_source=telegram&utm_medium=organic_social&utm_campaign=disclosure_push">
                                <h5 className="history-subject"><span className="history-date">2022.3</span> <span id="history-2022-3">invests in Aster(ATC) following Kakao Klay coin</span></h5>
                            </a>
                        </div>
                        <div className="col-lg-8">
                            <a className="history-link" target="_blank" href="https://xangle.io/project/ATC/recent-disclosure/612f087f379a7b6f35019fda">
                                <h5 className="history-subject"><span className="history-date">2021.9</span> <span id="history-2021-9">Aster(ATC) listed on Hanbitco</span></h5>
                            </a>
                        </div>
                        <div className="col-lg-8">
                            <a className="history-link" target="_blank" href="https://xangle.io/project/ATC/recent-disclosure/60ee926537b0f42099b17804">
                                <h5 className="history-subject"><span className="history-date">2021.7</span> <span id="history-2021-7">Opened Aster(ATC) Ecosystem METAREX</span></h5>
                            </a>
                        </div>
                        <div className="col-lg-8">
                            <a className="history-link" target="_blank" href="https://www.joongang.co.kr/article/24033309#home">
                                <h5 className="history-subject"><span className="history-date">2021.4</span> <span id="history-2021-4">Tenspace-Awesomepia Strategic Alliance</span></h5>
                            </a>
                        </div>
                        <div className="col-lg-8">
                            <a className="history-link" target="_blank" href="https://biz.chosun.com/site/data/html_dir/2021/03/25/2021032502354.html">
                                <h5 className="history-subject"><span className="history-date">2021.3</span> <span id="history-2021-3">Daehong Communications, MOU with Awesomepia</span></h5>
                            </a>
                        </div>
                        <div className="col-lg-8">
                            <a className="history-link" target="_blank" href="https://www.blockchaintoday.co.kr/news/articleView.html?idxno=13897">
                                <h5 className="history-subject"><span className="history-date">2020.11</span> <span id="history-2020-11">Aster(ATC) listed in Bithumb Global</span></h5>
                            </a>
                        </div>
                        <div className="col-lg-8">
                            <a className="history-link" target="_blank" href="https://zdnet.co.kr/view/?no=20200515093944">
                                <h5 className="history-subject"><span className="history-date">2019.10</span> <span id="history-2019-10">Tenspace Selected as a Cool Vendor in 2020 Gartner Banking and Investment AI</span></h5>
                            </a>
                        </div>
                        <br />
                        <br />
                        <br />
                    </div>
                </div>
            </div>
        </MainDiv>        
    )
}

export default Services;